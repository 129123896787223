import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ReadWepPageService } from 'src/app/services/read-wep-page.service';
import { environment } from 'src/environments/environment';
import { Interest } from 'src/app/shared/interest';
import { Agegroup } from 'src/app/shared/agegroup';
import { RestApiService } from 'src/app/services/rest-api.service';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerForm: UntypedFormGroup;
  loading: boolean = true;
  registrationSend: boolean = false;
  submitted: boolean = false;
  returnUrl: string;
  apiInterests: Array<Interest>;
  apiAgegroups: Array<Agegroup>;
  socialUser: any;
  socialError: string = null;
  socialLoading: boolean = false;
  dummyPw: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private webpage: ReadWepPageService,
    private restApi: RestApiService,
    private alertService: AlertService,
    private socialAuthService: SocialAuthService,
    private messagingService: MessagingService
  ) {
    this.authenticationService.setSocialContextProcess('init');

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/account']);
    }
  }

  ngOnInit() {
    forkJoin([this.restApi.getInterests(), this.restApi.getAgegroups()])
      .pipe(
        map(([interests, agegroups]) => {
          // forkJoin returns an array of values, here we map those values to an object
          return { interests, agegroups };
        })
      )
      .subscribe((data) => {
        this.apiInterests = data.interests.items;
        this.apiAgegroups = data.agegroups.items;

        this.buildForm();

        this.loading = false;
      });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/account';

    this.socialAuthService.authState.subscribe(
      (user) => {
        this.socialUser = user;

        if (this.authenticationService.getSocialContextProcess() === 'init' ||
            this.authenticationService.getSocialContextProcess() === 'logout') {
          this.socialUser = null;
          this.authenticationService.setSocialContextProcess(null);
        }

        const isProviderGoogle = this.socialUser && this.socialUser.provider.toLowerCase() === 'google';
        if (isProviderGoogle) {
          this.authenticationService.setSocialContextProcess('register');
        }

        if (
          this.socialUser !== null &&
          this.authenticationService.getSocialContextProcess() === 'register'
        ) {
          this.socialLoading = true;
          this.authenticationService.setSocialContextProcess('running');
          this.socialLogin();
        }
      },
      (error) => {
        this.socialError = error;
        this.socialLoading = false;
      }
    );
  }

  socialLogin(): void {
    this.authenticationService
      .socialLogin(
        this.socialUser.email,
        this.socialUser.authToken,
        this.socialUser.provider
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          this.authenticationService
            .refreshUserData()
            .subscribe((data) => {
              // PushToken aktualisieren falls noch nicht belegt
              if (Notification.permission === 'granted') {
                this.messagingService.getToken().subscribe((token) => {
                  this.restApi.getToken(token).subscribe((result) => {
                    if (result.success && result.user !== data.user.uid) {
                      this.restApi
                        .updateToken(token, data.user.uid)
                        .subscribe();
                    }
                  });
                });
              }
              this.socialLoading = false;
              this.authenticationService.setSocialContextProcess('');
              this.router.navigate([this.returnUrl]);
            });
        },
        (error) => {
          this.socialError = error;
          this.socialLoading = false;
          this.authenticationService.setSocialContextProcess('');
        }
      );
  }

  registerWithFacebook(): void {
    this.authenticationService.setSocialContextPlatform('facebook');
    this.authenticationService.setSocialContextProcess('register');
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  buildForm() {
    this.registerForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        firstName: [''],
        lastName: [''],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        interests: this.formBuilder.group({}),
        privacy: [false, [Validators.requiredTrue]],
        newsletter: [false],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    const checkboxes = <UntypedFormGroup>this.registerForm.get('interests');
    this.apiInterests.forEach((option: any) => {
      checkboxes.addControl(option.title, new UntypedFormControl());
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    var submitInterestes: Array<number> = this.getInterestsSubmit();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;

    this.webpage.getRawData(environment.registerUrl).subscribe(
      (data) => {
        let originalHtml: string = data;
        var parser = new DOMParser();

        var htmlDoc = parser.parseFromString(originalHtml, 'text/html');
        var submitUrl =
          environment.baseUrl.slice(0, -1) +
          htmlDoc.querySelector('form[name="user"]').getAttribute('action');

        var argumentsToken = htmlDoc
          .querySelector(
            'input[name="tx_femanager_pi1[__referrer][arguments]"]'
          )
          .getAttribute('value');

        var requestContent = htmlDoc
          .querySelector('input[name="tx_femanager_pi1[__referrer][@request]"]')
          .getAttribute('value');

        var trustedPropertiesContent = htmlDoc
          .querySelector('input[name="tx_femanager_pi1[__trustedProperties]"]')
          .getAttribute('value');

        //console.log(trustedPropertiesContent);

        this.authenticationService
          .register(
            this.f.email.value,
            this.f.firstName.value,
            this.f.lastName.value,
            this.f.password.value,
            submitInterestes,
            this.f.newsletter.value,
            submitUrl,
            argumentsToken,
            requestContent,
            trustedPropertiesContent
          )
          .subscribe((data) => {
            this.loading = false;
            this.registrationSend = true;
          });
      },
      (error) => console.log(error)
    );
  }

  getInterestsSubmit(): Array<number> {
    var newValues = [];
    Object.entries(this.f.interests.value).forEach((item) => {
      this.apiInterests.forEach((apiItem) => {
        if (apiItem.title === item[0] && item[1] === true) {
          newValues.push(apiItem.uid);
        }
      });
    });
    return newValues;
  }
}
