import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeoService } from 'src/app/services/geo.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Presentation } from 'src/app/shared/presentation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nearby',
  templateUrl: './nearby.component.html',
  styleUrls: ['./nearby.component.scss'],
})
export class NearbyComponent implements OnInit {
  apiPresentations: Presentation[];
  baseUrl = environment.baseUrl;
  presentations: Presentation[];
  loading: boolean = true;
  userLocation: any = null;
  hasUserLocation: boolean = false;
  radius: string;
  mapZoomLevel: number = 10;

  selectedAlphabet: string = 'all';

  constructor(
    private restApi: RestApiService,
    private router: Router,
    private geoService: GeoService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.radius = this.settingsService.locationRadius();
    this.restApi.getPresentations().subscribe((data) => {
      this.apiPresentations = data.items;
      this.refreshLocation();
    });
  }

  refreshLocation(): void {
    this.loading = true;
    this.geoService.getPosition().then(
      (pos) => {
        this.userLocation = pos;
        this.hasUserLocation = true;
        this.applyFilters();
        this.loading = false;
        // console.log(pos);
      },
      (reason) => {
        this.userLocation = {
          lat: 51.4882232,
          lng: 6.8756003,
        };
        this.applyFilters();
        this.loading = false;
      }
    );
  }

  changeLocationRadius() {
    this.settingsService.locationRadius(this.radius);
    this.applyFilters();
  }

  applyFilters(): void {
    this.presentations = [];
    this.apiPresentations.forEach((item) => {
      var match: boolean = true;

      item.imagesDisp = Object.values(item.images);

      item.distance = Math.round(
        this.geoService.distance(
          this.userLocation.lat,
          this.userLocation.lng,
          item.latitude,
          item.longitude,
          'K'
        )
      );

      // Buchstaben
      if (this.selectedAlphabet !== 'all') {
        var alphabetArray: Array<string> = this.selectedAlphabet.split('-');
        if (
          item.title.charAt(0).toLowerCase() < alphabetArray[0] ||
          item.title.charAt(0).toLowerCase() > alphabetArray[1]
        )
          match = false;
      }

      if (this.settingsService.locationRadius() !== 'all') {
        if (item.distance > parseInt(this.settingsService.locationRadius())) {
          match = false;
        }
        switch (parseInt(this.settingsService.locationRadius())) {
          case 5:
            this.mapZoomLevel = this.hasUserLocation ? 14 : 12;
            break;
          case 10:
          case 20:
            this.mapZoomLevel = this.hasUserLocation ? 13 : 11;
            break;
          default:
            this.mapZoomLevel = this.hasUserLocation ? 12 : 10;
            break;
        }
      }

      if (match) {
        item.iconurl =
          environment.baseUrl +
          `typo3conf/ext/dvd_presentation/Resources/Public/img/marker_s_${item.category.uid}.png`;
        this.presentations.push(item);
      }
    });

    this.presentations.sort((a, b) => {
      return a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0;
    });
  }

  gotoMaps(lat: number, long: number): void {
    let url = `https://maps.google.com/?q=${lat},${long}`;
    window.open(url, '_blank');
  }
}
