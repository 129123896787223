<div class="uk-container">
  <div uk-sticky="offset: 70" class="uk-margin-small-bottom appsubnav">
    <div uk-grid>
      <div>
        <button class="uk-button uk-button-default" appLogoutButton>
          <span i18n="logout">Logout</span>
        </button>
      </div>
      <div class="uk-width-expand uk-hidden@m">
        <select
          class="uk-select"
          (change)="changeAccountPage()"
          [(ngModel)]="currentAccountPage"
        >
          <option value="/account">Startseite</option>
          <option value="/account/profile">Profil</option>
          <option value="/account/favorites">Favoriten</option>
          <option value="/account/mycard">MyCard</option>
        </select>
      </div>
    </div>
  </div>
  <div id="main">
    <div id="content">
      <main>
        <div uk-grid="" class="profile-container uk-flex">
          <div
            class="profile-left-container uk-visible@m uk-width-1-5@m uk-flex-last uk-flex-first@m"
          >
            <a [routerLink]="'/account/profile'"> Profil </a>
            <a [routerLink]="'/account/favorites'" class="active">
              Favoriten
            </a>
            <a [routerLink]="'/account/mycard'"> MyCard </a>
          </div>

          <div class="profile-right-container femanager_edit uk-width-4-5@m">
            <p>
              Stöbert durch die RUHR.TOPCARD Ausflugsziele und speichert eure
              Favoriten.
            </p>

            <p>
              <a
                [routerLink]="'/destinations'"
                class="uk-button uk-button-primary"
                >Ausflugsziele</a
              >
            </p>

            <div uk-grid>
              <div
                *ngFor="let favorite of favorites"
                class="item uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l uk-position-relative mmloc"
              >
                <div class="item-inner uk-align-center">
                  <div class="item-image">
                    <a [routerLink]="'/destinations/detail/' + favorite.uid">
                      <div class="item-image-inner uk-cover-container">
                        <canvas width="345" height="240"></canvas>
                        <img
                          class="img-responsive"
                          src="{{
                            baseUrl + '/' + favorite.dispImages[0].fileProcessed
                          }}"
                          alt=""
                          title="{{ favorite.title }}"
                          uk-cover
                        />
                      </div>
                    </a>
                  </div>
                  <div uk-grid>
                    <div class="item-info uk-width-3-4">
                      <a [routerLink]="'/destinations/detail/' + favorite.uid">
                        <div class="title">{{ favorite.title }}</div>
                        <div class="city">{{ favorite.city }}</div>
                      </a>
                    </div>

                    <div class="uk-width-1-4">
                      <a
                        class="uk-icon-button uk-margin-small-top"
                        (click)="deleteFromFavorites(favorite.uid)"
                        uk-icon="trash"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
