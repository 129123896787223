import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    let dontAuthorize = this.authenticationService.dontAuthorize;
    if (
      currentUser &&
      currentUser.jwt_token &&
      dontAuthorize.valueOf() == false
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.jwt_token}`,
        },
      });
    }
    this.authenticationService.setDontAuthorize(new Boolean(false));

    return next.handle(request);
  }
}
