import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { News } from 'src/app/shared/news';
import { RestApiService } from 'src/app/services/rest-api.service';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

import * as sha1 from 'js-sha1';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  loading: boolean = true;
  baseUrl = environment.baseUrl;
  id: string = this.actRoute.snapshot.params['id'];
  news: News = new News();
  media: any[];

  constructor(
    private actRoute: ActivatedRoute,
    private restApi: RestApiService
  ) {}

  ngOnInit(): void {
    this.loadNews(this.id);
  }

  loadNews(id) {
    //console.log(this.id);
    return this.restApi.getNews().subscribe((res) => {
      res.items.forEach((item) => {
        //console.log(this.hashCode(item.datetime + item.title));
        if (id === this.hashCode(item.datetime + item.title)) {
          this.news = item;
          this.media = Object.values(this.news.media);
          this.loading = false;
        }
      });
    });
  }

  hashCode(idString): string {
    return sha1(idString);
  }
}
