import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ReadWepPageService } from 'src/app/services/read-wep-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
})
export class ImpressumComponent implements AfterViewInit {
  loading: boolean = true;
  htmlContent: string = '';
  h1: string = '';
  @ViewChild('websitecontent') contentElement: ElementRef;

  constructor(
    private webpage: ReadWepPageService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.getRawData();
  }

  getRawData() {
    this.webpage.getRawData(environment.impressumUrl).subscribe(
      (data) => {
        let originalHtml: string = data;
        var parser = new DOMParser();

        var htmlDoc = parser.parseFromString(originalHtml, 'text/html');
        var htmlContent = htmlDoc.querySelector('#content main');
        //this.h1 = htmlDoc.querySelector("h1").innerHTML;

        var images = htmlContent.querySelectorAll('img');
        images.forEach((img) => {
          img.src = img.src.replace(
            'https://www.ruhrtopcard.de',
            'https://www.ruhrtopcard.de'
          );
          
        });

        var newEl = this.renderer.createElement('main');
        this.renderer.setProperty(newEl, 'innerHTML', htmlContent.innerHTML);
        this.renderer.appendChild(this.contentElement.nativeElement, newEl);

        this.loading = false;
      },
      (error) => console.log(error)
    );
  }
}
