<div class="uk-container">
  <div uk-sticky="offset: 70" class="uk-margin-small-bottom appsubnav">
    <div uk-grid class="uk-margin-small-top uk-margin-small-bottom">
      <div>
        <button
          class="uk-button uk-button-default uk-button-small"
          appBackButton
        >
          <span uk-icon="chevron-left"></span>
        </button>
      </div>
      <div>
        <a
          [href]="
            sanitize(
              'geo:' + presentation.latitude + ',' + presentation.longitude
            )
          "
          class="
            uk-button uk-button-small uk-button-primary uk-margin-small-left
          "
          target="_blank"
          ><span uk-icon="location"></span
        ></a>
      </div>
      <div *ngIf="!userData || (userData && !isFavorite)">
        <button
          class="
            uk-button uk-button-small uk-button-default uk-margin-small-left
          "
          (click)="addToFavorites()"
        >
          <span uk-icon="heart" *ngIf="favoritesLoading == false"></span>
          <span *ngIf="favoritesLoading" uk-spinner="ratio: 0.75"></span>
        </button>
      </div>
      <div *ngIf="userData && isFavorite">
        <button
          class="
            uk-button uk-button-small uk-button-primary uk-margin-small-left
          "
          style="background-color: red"
          (click)="deleteFromFavorites()"
        >
          <span uk-icon="heart" *ngIf="favoritesLoading == false"></span>
          <span *ngIf="favoritesLoading" uk-spinner="ratio: 0.75"></span>
        </button>
      </div>
    </div>
  </div>
  <div id="main">
    <div id="content" *ngIf="loading === false">
      <main>
        <!--  CONTENT ELEMENT, uid:2090/list [begin] -->
        <div id="c2090" class="csc-frame csc-frame-default">
          <!--  Plugin inserted: [begin] -->

          <div
            class="leistung mode_none locitem"
            data-is-bookmarked=""
            data-bookmark-url="/rest/v1/presentation/2162/bookmark/"
            data-user-id=""
            data-user-hash=""
            data-test="1"
          >
            <div>
              <div>
                <h1>
                  <span>{{ presentation.title }}</span>
                </h1>
              </div>
              <div *ngIf="presentation.category">
                <h2 class="category">{{ presentation.category.title }}</h2>
              </div>

              <div class="partnerDescritption uk-text-center@s">
                <div
                  [innerHtml]="presentation.description"
                  class="bodytext"
                ></div>

                <!-- <div class="item-links uk-margin-large-bottom">
                  <a
                    class="mloc"
                    (click)="addToFavorites()"
                    *ngIf="userData && !isFavorite"
                    >+ als Favorit speichern</a
                  >
                  <a
                    class="mloc"
                    (click)="deleteFromFavorites()"
                    *ngIf="userData && isFavorite"
                    >- aus Favoriten entfernen</a
                  >
                </div> -->
              </div>

              <div class="gallery">
                <div
                  class="
                    uk-position-relative
                    partner-detail-carousel
                    uk-slider uk-slider-container
                  "
                  tabindex="-1"
                  uk-slider="finite: false; center: true"
                >
                  <ul
                    class="uk-slider-items uk-grid"
                    style="transform: translate3d(0px, 0px, 0px)"
                  >
                    <li
                      *ngFor="let video of ytvideos"
                      class="uk-width-3-4 uk-width-1-3@m item video"
                      uk-lightbox
                    >
                      <a
                        href="https://www.youtube.com/watch?v={{ video }}"
                        rel="group_{{ presentation.uid }}"
                      >
                        <div class="uk-cover-container uk-inline">
                          <canvas width="720" height="405"></canvas>
                          <img
                            src="https://img.youtube.com/vi/{{
                              video
                            }}/mqdefault.jpg"
                            class="img-responsive"
                            uk-cover
                          />
                          <div
                            class="
                              uk-overlay uk-overlay-default uk-position-center
                              playbutton
                            "
                            style="background: transparent"
                          >
                            <svg
                              width="100"
                              height="100"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <mask id="button">
                                <rect
                                  x="0"
                                  y="0"
                                  width="100"
                                  height="100"
                                  fill="white"
                                />
                                <path
                                  d="m40.30937,24l32.89793,24.37631l-33.2073,24.01086l0.30937,-48.38716l0,-0.00001z"
                                />
                              </mask>

                              <g>
                                <ellipse
                                  cx="50"
                                  cy="50"
                                  rx="50"
                                  ry="50"
                                  mask="url(#button)"
                                />
                                <path
                                  d="m40.30937,24l32.89793,24.37631l-33.2073,24.01086l0.30937,-48.38716l0,-0.00001z"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      *ngFor="let image of images"
                      class="uk-width-3-4 uk-width-1-3@m item"
                      tabindex="-1"
                    >
                      <div class="uk-cover-container">
                        <canvas width="720" height="405"></canvas>
                        <img
                          class="img-responsive"
                          src="{{ baseUrl + '/' + image.fileProcessed }}"
                          alt=""
                          uk-cover
                        />
                      </div>
                    </li>
                  </ul>
                  <a
                    class="
                      uk-position-center-left uk-position-small
                      control-left
                      uk-visible@m
                    "
                    href="#"
                    uk-slider-item="previous"
                    ><img
                      src="{{
                        baseUrl +
                          '/typo3conf/ext/dvd_wnr_website/Resources/Public/img/left-arrow.png'
                      }}"
                      alt=""
                      width="27"
                      height="16"
                  /></a>
                  <a
                    class="
                      uk-position-center-right uk-position-small
                      control-right
                      uk-visible@m
                    "
                    href="#"
                    uk-slider-item="next"
                    ><img
                      src="{{
                        baseUrl +
                          '/typo3conf/ext/dvd_wnr_website/Resources/Public/img/right-arrow.png'
                      }}"
                      alt=""
                      width="27"
                      height="16"
                  /></a>
                </div>
              </div>

              <div class="performance-container">
                <div
                  class="item"
                  [attr.style]="'height: ' + infobox.height + 'px'"
                  *ngFor="let infobox of infoboxes; let i = index"
                >
                  <div class="label-container">
                    <div class="background">
                      <svg
                        [attr.style]="'height: ' + (infobox.height - 10) + 'px'"
                      >
                        <polygon [attr.points]="infobox.svg"></polygon>
                      </svg>
                    </div>

                    <div
                      class="label"
                      [attr.style]="'max-width: ' + infobox.width + 'px'"
                      i18n="{{ infobox.label }}"
                    >
                      {{ infobox.label }}
                    </div>
                  </div>
                  <div class="content" [innerHtml]="infobox.content"></div>
                </div>
              </div>

              <div class="opening-hours-container">
                <h2 i18n="Öffnungszeiten">Öffnungszeiten</h2>

                <div
                  class="
                    uk-child-width-1-1
                    uk-child-width-1-2@s
                    uk-child-width-1-4@m
                    uk-grid
                    uk-grid-stack
                  "
                  uk-grid=""
                  uk-height-match="target: > .box > .inner"
                >
                  <div class="box" *ngFor="let oh of openingHours">
                    <div class="inner" [innerHtml]="oh"></div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <!-- <div> -->
              <!---->

              <!--<div class="undermap">-->
              <!--<a target="_blank" href="http://maps.google.com/maps?daddr=B%C3%B6ddinghauser%20Feld%201%20%28nahe%20Schulzentrum%29%2C%2058840%20Plettenberg%2C%20Deutschland&amp;mode=driving">Anfahrt mit dem PKW</a> | <a target="_blank" href="http://efa.vrr.de/vrrstd/XSLT_TRIP_REQUEST2?language=de&itdLPxx_transpCompany=vrr&name_destination=Plettenberg%2C%20B%C3%B6ddinghauser%20Feld%201%20%28nahe%20Schulzentrum%29&type_destination=any">Anfahrt mit dem ÖPNV (VRR)</a>-->
              <!--</div>-->
              <!---->
              <!--</div>-->
              <!--</div>-->

              <div>
                <div>
                  <div class="address">
                    <div
                      uk-grid=""
                      class="
                        uk-child-width-1-1
                        uk-child-width-1-2@s
                        uk-grid-collapse
                        uk-grid
                      "
                    >
                      <div class="vcenter uk-first-column">
                        <div>
                          <div class="content">
                            <h2>Adresse &amp; Kontakt</h2>
                            <div
                              class="
                                uk-padding-small uk-padding-remove-left
                                address-inner
                              "
                            >
                              <p>
                                {{ presentation.address }}<br />
                                {{ presentation.zip }} {{ presentation.city
                                }}<br />
                                Tel. {{ presentation.phone }}<br />

                                <a href="mailto:{{ presentation.email }}">{{
                                  presentation.email
                                }}</a
                                ><br />

                                <a
                                  href="{{ presentation.homepage }}"
                                  target="_blank"
                                  >{{ presentation.homepage }}</a
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div id="map">
                          <!--<a href="https://maps.googleapis.com/maps/api/staticmap?center=Böddinghauser Feld 1 (nahe Schulzentrum),58840%20Plettenberg&zoom=15&size=640x600&maptype=roadmap&markers=color:red%7CBöddinghauser Feld 1 (nahe Schulzentrum),58840%20Plettenberg" title="Böddinghauser Feld 1 (nahe Schulzentrum),58840 Plettenberg" class="fancybox">-->
                          <img
                            src="https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAuYBmWj-Bq1VVX2j14_4EIey6UoWAh7zg&amp;center={{
                              presentation.address
                            }},{{ presentation.zip }}%20{{
                              presentation.city
                            }}&amp;zoom=15&amp;scale=2&amp;size=750x415&amp;maptype=roadmap&amp;markers=color:red%7C{{
                              presentation.address
                            }},{{ presentation.zip }}%20{{ presentation.city }}"
                            alt=""
                            class="img-responsive uk-float-right"
                          />
                          <!--</a>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="
                uk-container uk-text-center
                presentationRatingContainer
                uk-margin-top uk-margin-bottom
              "
              *ngIf="userData"
            >
              <h5>Gebt hier Eure Bewertung ab!</h5>
              <div id="presentationRating">
                <span
                  uk-icon="star"
                  data-rating="1"
                  (click)="selectRating(1)"
                  (mouseover)="highlightElements(1)"
                  (mouseleave)="highlightElements(defaultRating)"
                  [class.highlighted]="presentation.ratingAvg >= 0.5"
                ></span>
                <span
                  uk-icon="star"
                  data-rating="2"
                  (click)="selectRating(2)"
                  (mouseover)="highlightElements(2)"
                  (mouseleave)="highlightElements(defaultRating)"
                  [class.highlighted]="presentation.ratingAvg >= 1.5"
                ></span>
                <span
                  uk-icon="star"
                  data-rating="3"
                  (click)="selectRating(3)"
                  (mouseover)="highlightElements(3)"
                  (mouseleave)="highlightElements(defaultRating)"
                  [class.highlighted]="presentation.ratingAvg >= 2.5"
                ></span>
                <span
                  uk-icon="star"
                  data-rating="4"
                  (click)="selectRating(4)"
                  (mouseover)="highlightElements(4)"
                  (mouseleave)="highlightElements(selectedRating)"
                  [class.highlighted]="presentation.ratingAvg >= 3.5"
                ></span>
                <span
                  uk-icon="star"
                  data-rating="5"
                  (click)="selectRating(5)"
                  (mouseover)="highlightElements(5)"
                  (mouseleave)="highlightElements(defaultRating)"
                  [class.highlighted]="presentation.ratingAvg >= 4.5"
                ></span>

                <div>
                  <button
                    (click)="submitRating()"
                    class="uk-button uk-button-primary uk-button-small"
                  >
                    <span *ngIf="!ratingLoading">Absenden</span>
                    <span *ngIf="ratingLoading" uk-spinner="ratio: 0.75"></span>
                  </button>
                </div>
                <div
                  *ngIf="ratingError"
                  class="uk-text-danger uk-margin-bottom"
                >
                  Bitte Anzahl Sterne auswählen
                </div>
              </div>
            </div>

            <!--  Plugin inserted: [end] -->
          </div>
          <!--  CONTENT ELEMENT, uid:2090/list [end] -->
        </div>
      </main>
    </div>
  </div>

  <!--noindex-->
</div>
