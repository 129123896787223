<div class="tx-dvd-presentation">
  <div class="leistungen mode_all">
    <!--<h1>Alle Leitungen</h1>-->
    <div class="service-list">
      <div class="uk-container">
        <div>
          <div
            class="service-list-header uk-margin-small-top uk-margin-small-bottom"
          >
            <div class="uk-child-width-1-2 uk-child-width-1-4@m" uk-grid>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations/nearby'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-primary"
                  >in der Umgebung</a
                >
              </div>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-default"
                  >Alle</a
                >
              </div>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations/cities'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-primary"
                  >nach Städten</a
                >
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="service-list-filter">
            <div uk-grid>
              <div class="uk-width-1-1 uk-width-1-4@s uk-width-1-6@m">
                <span>Eure Suchfilter:</span>
              </div>
              <div class="uk-width-1-1 uk-width-3-4@s uk-width-5-6@m">
                <form class="uk-form-horizontal filter-form">
                  <div
                    class="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m"
                    uk-grid
                  >
                    <div>
                      <label class="uk-form-label" for="selectedAlphabet"
                        >A-Z
                      </label>
                      <div class="uk-form-controls">
                        <select
                          class="service-list-select uk-select uk-form-small"
                          id="selectedAlphabet"
                          name="selectedAlphabet"
                          data-service-list-filters="1"
                          (change)="applyFilters()"
                          [(ngModel)]="selectedAlphabet"
                        >
                          <option value="all">Alle</option>
                          <option value="0-9">0-9</option>
                          <option value="a-c">A-C</option>
                          <option value="d-f">D-F</option>
                          <option value="g-i">G-I</option>
                          <option value="j-m">J-M</option>
                          <option value="n-s">N-S</option>
                          <option value="t-z">T-Z</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label class="uk-form-label" for="selectedCategory"
                        >Kategorien
                      </label>
                      <div class="uk-form-controls">
                        <select
                          id="selectedCategory"
                          name="selectedCategory"
                          class="service-list-select uk-select uk-form-small"
                          data-service-list-filters="1"
                          (change)="applyFilters()"
                          [(ngModel)]="selectedCategory"
                        >
                          <option value="all">Alle</option>

                          <option
                            *ngFor="let category of categories"
                            value="{{ category }}"
                          >
                            {{ category }}
                          </option>

                          <!--  <option
                            data-categories="special"
                            data-filter-categories="special"
                          >
                            Special
                          </option> -->
                        </select>
                      </div>
                    </div>
                    <div>
                      <label for="pricehalf" class="uk-form-label"
                        >Ermäßigung</label
                      >
                      <div class="uk-form-controls">
                        <select
                          id="pricehalf"
                          name="pricehalf"
                          class="service-list-select uk-form-small uk-select"
                          data-service-list-filters="1"
                          (change)="applyFilters()"
                          [(ngModel)]="selectedPrice"
                        >
                          <option value="all">Alle</option>

                          <option value="0">nur Eintritt frei</option>

                          <option value="1">nur halber Preis</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="uk-container"
        *ngFor="let presentation of presentations"
        data-service-list-filter-item="1"
        data-filter-chars="0-9"
        data-filter-chars-all="1"
        data-filter-categories="7"
        data-filter-categories-all="all"
        data-filter-fullhalfspecial="1"
      >
        <div
          uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-medium; delay: 150"
        >
          <div
            class="service-list-item locitem uk-grid-collapse"
            locid="1026"
            data-chars="0-9"
            data-categories="7"
            data-fullhalfspecial="1"
            uk-grid
          >
            <div class="uk-width-auto">
              <img
                width="90"
                src="{{
                  baseUrl + '/' + presentation.imagesDisp[0].fileProcessed
                }}"
              />
            </div>

            <div
              class="uk-width-expand uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
            >
              <a [routerLink]="'/destinations/detail/' + presentation.uid">
                {{ presentation.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
