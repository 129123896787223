<div class="uk-width-1-1 uk-text-center" *ngIf="loading === true">
  <div class="uk-margin-top" uk-spinner></div>
</div>

<div class="tx-dvd-presentation" *ngIf="loading === false">
  <div class="leistungen mode_all">
    <!--<h1>Alle Leitungen</h1>-->
    <div class="service-list">
      <div class="uk-container">
        <div>
          <div
            class="service-list-header uk-margin-small-top uk-margin-small-bottom"
          >
            <div class="uk-child-width-1-2 uk-child-width-1-4@m" uk-grid>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations/nearby'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-default"
                  >in der Umgebung</a
                >
              </div>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-primary"
                  >Alle</a
                >
              </div>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations/cities'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-primary"
                  >nach Städten</a
                >
              </div>
            </div>
          </div>
        </div>

        <agm-map
          [latitude]="userLocation.lat"
          [longitude]="userLocation.lng"
          [zoom]="mapZoomLevel"
        >
          <agm-marker
            *ngFor="let presentation of presentations"
            [latitude]="presentation.latitude"
            [longitude]="presentation.longitude"
            [iconUrl]="presentation.iconurl"
          >
            <agm-info-window
              ><a [routerLink]="'/destinations/detail/' + presentation.uid">{{
                presentation.title
              }}</a></agm-info-window
            >
          </agm-marker>
        </agm-map>
        <button
          (click)="refreshLocation()"
          class="uk-button uk-button-small uk-button-primary uk-align-center uk-margin-small-top"
        >
          meinen Standort neu ermitteln
        </button>

        <div class="uk-text-center">
          <label class="uk-form-label uk-margin-small-right" for="radius"
            >Suchradius</label
          >
          <select
            class="uk-select uk-width-1-3"
            (change)="changeLocationRadius()"
            [(ngModel)]="radius"
            name="radius"
            id="radius"
          >
            <option value="5">5 km</option>
            <option value="10">10 km</option>
            <option value="20">20 km</option>
            <option value="50">50 km</option>
            <option value="100">100 km</option>
            <option value="all">unbegrenzt</option>
          </select>
        </div>
      </div>

      <div
        class="uk-container"
        *ngFor="let presentation of presentations"
        data-service-list-filter-item="1"
        data-filter-chars="0-9"
        data-filter-chars-all="1"
        data-filter-categories="7"
        data-filter-categories-all="all"
        data-filter-fullhalfspecial="1"
      >
        <div
          uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-medium; delay: 150"
        >
          <div
            class="service-list-item locitem uk-grid-collapse"
            locid="1026"
            data-chars="0-9"
            data-categories="7"
            data-fullhalfspecial="1"
            uk-grid=""
          >
            <div class="uk-width-auto">
              <img
                width="90"
                src="{{
                  baseUrl + '/' + presentation.imagesDisp[0].fileProcessed
                }}"
              />
            </div>

            <div
              class="uk-width-expand uk-child-width-1-1 uk-grid-collapse"
              uk-grid
            >
              <div
                class="uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
              >
                <a [routerLink]="'/destinations/detail/' + presentation.uid">
                  {{ presentation.title }}
                </a>
              </div>
              <div
                class="bodytext uk-margin-small-top uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                *ngIf="hasUserLocation"
              >
                <button
                  (click)="
                    gotoMaps(presentation.latitude, presentation.longitude)
                  "
                  class="uk-button uk-button-small uk-button-primary"
                >
                  Entfernung {{ presentation.distance }} km
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
