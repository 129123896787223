import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ReadWepPageService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  getRawData(url: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/html');
    this.authenticationService.setDontAuthorize(new Boolean(true));
    return this.http.get(url, {
      //headers: headers,
      responseType: 'text',
      withCredentials: true,
    });
  }
}
