import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserData } from 'src/app/shared/user-data';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  userData: UserData;
  currentAccountPage: string = '/account';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    this.authenticationService.currentUserData.subscribe((value) => {
      this.userData = value;
    });
  }

  changeAccountPage() {
    const selectedAccountPage: string = this.currentAccountPage;
    this.currentAccountPage = '/account';
    this.router.navigate([selectedAccountPage]);
  }
}
