import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReadWepPageService } from 'src/app/services/read-wep-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements AfterViewInit {
  loading: boolean = true;
  htmlContent: string = '';
  h1: string = '';
  @ViewChild('websitecontent') contentElement: ElementRef;

  constructor(
    private webpage: ReadWepPageService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit(): void {
    this.getRawData();
  }

  getRawData() {
    this.webpage.getRawData(environment.infoUrl).subscribe(
      (data) => {
        let originalHtml: string = data;
        var parser = new DOMParser();

        var htmlDoc = parser.parseFromString(originalHtml, 'text/html');
        var htmlContent = htmlDoc.querySelector('#content main');
        //var htmlContent = this.sanitizer.bypassSecurityTrustHtml(htmlDoc).toString();
        this.h1 = htmlDoc.querySelector('h1')
          ? htmlDoc.querySelector('h1').innerHTML
          : htmlDoc.title;

        var images = htmlContent.querySelectorAll("img");
        images.forEach(img => {
          img.src= img.src.replace("https://www.ruhrtopcard.de", "https://www.ruhrtopcard.de");
          //console.log(img.src)
        });
        var newEl = this.renderer.createElement('main');
        this.renderer.setProperty(newEl, 'innerHTML',  htmlContent.innerHTML);
        this.renderer.appendChild(this.contentElement.nativeElement, newEl);

        this.loading = false;
      },
      (error) => console.log(error)
    );
  }
}
