import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { skip, filter, take } from 'rxjs/operators';
import {
  BaseLoginProvider,
} from '@abacritt/angularx-social-login';

class SocialUser {
  idToken: any;
  id: any;
  name: any;
  email: any;
  photoUrl: any;
  firstName: any;
  lastName: any;
}

const defaultInitOptions = {
  oneTapEnabled: true,
};
// @ts-ignore
class GoogleLoginProviderOverride extends BaseLoginProvider {
  private clientId: any;
  private initOptions: any;
  private changeUser: EventEmitter<any>;
  private _socialUser: BehaviorSubject<null>;
  private _accessToken: BehaviorSubject<null>;
  private _receivedAccessToken: EventEmitter<any>;
  private _tokenClient: any;
  constructor(clientId, initOptions) {
    super();
    this.clientId = clientId;
    this.initOptions = initOptions;
    this.changeUser = new EventEmitter();
    this._socialUser = new BehaviorSubject(null);
    this._accessToken = new BehaviorSubject(null);
    this._receivedAccessToken = new EventEmitter();
    this.initOptions = { ...defaultInitOptions, ...this.initOptions };
    // emit changeUser events but skip initial value from behaviorSubject
    this._socialUser.pipe(skip(1)).subscribe(this.changeUser);
    // emit receivedAccessToken but skip initial value from behaviorSubject
    this._accessToken.pipe(skip(1)).subscribe(this._receivedAccessToken);
  }
  // @ts-ignore
  initialize(autoLogin) {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript('GOOGLE', 'https://accounts.google.com/gsi/client', () => {
          google["accounts"].id.initialize({
            client_id: this.clientId,
            auto_select: autoLogin,
            callback: ({ credential }) => {
              const socialUser = this.createSocialUser(credential);
              // @ts-ignore
              this._socialUser.next(socialUser);
            },
            prompt_parent_id: this.initOptions?.prompt_parent_id,
            itp_support: this.initOptions.oneTapEnabled,

            // extend with options
            ux_mode: this.initOptions.ux_mode,
            login_uri: this.initOptions.login_uri
          });
          if (this.initOptions.oneTapEnabled) {
            this._socialUser
              .pipe(filter((user) => user === null))
              .subscribe(() => google["accounts"].id.prompt(console.debug));
          }
          if (this.initOptions.scopes) {
            const scope = this.initOptions.scopes instanceof Array
              ? this.initOptions.scopes.filter((s) => s).join(' ')
              : this.initOptions.scopes;
            this._tokenClient = google["accounts"].oauth2.initTokenClient({
              client_id: this.clientId,
              scope,
              prompt: this.initOptions.prompt,
              callback: (tokenResponse) => {
                if (tokenResponse.error) {
                  this._accessToken.error({
                    code: tokenResponse.error,
                    description: tokenResponse.error_description,
                    uri: tokenResponse.error_uri,
                  });
                }
                else {
                  this._accessToken.next(tokenResponse.access_token);
                }
              },
            });
          }
          // @ts-ignore
          resolve();
        });
      }
      catch (err) {
        reject(err);
      }
    });
  }
  createSocialUser(idToken) {
    const user = new SocialUser();
    user.idToken = idToken;
    const payload = this.decodeJwt(idToken);
    user.id = payload.sub;
    user.name = payload.name;
    user.email = payload.email;
    user.photoUrl = payload.picture;
    user.firstName = payload['given_name'];
    user.lastName = payload['family_name'];
    return user;
  }
  decodeJwt(idToken) {
    const base64Url = idToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(window.atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""));
    return JSON.parse(jsonPayload);
  }
}

export { GoogleLoginProviderOverride, SocialUser };
