import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mycard',
  templateUrl: './mycard.component.html',
  styleUrls: ['./mycard.component.scss'],
})
export class MycardComponent implements OnInit {
  currentAccountPage: string = '/account/mycard';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  changeAccountPage() {
    const selectedAccountPage: string = this.currentAccountPage;
    this.currentAccountPage = '/account/mycard';
    this.router.navigate([selectedAccountPage]);
  }
}
