import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Presentation } from 'src/app/shared/presentation';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/shared/user';
declare var UIkit: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  apiPresentations: Presentation[];
  search: string = '';
  jwtHelper = new JwtHelperService();
  currentUser: User = null;

  constructor(
    private restApi: RestApiService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.restApi.getPresentations().subscribe((data) => {
      this.apiPresentations = data.items;
    });
    this.authenticationService.currentUser.subscribe(
      (value) => (this.currentUser = value)
    );
  }

  closeSearch(): void {
    this.search = '';
    UIkit.modal(document.getElementById('search-modal-full')).hide();
  }
}
