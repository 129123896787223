<div class="tx-dvd-presentation">
  <div class="leistungen mode_all">
    <!--<h1>Alle Leitungen</h1>-->
    <div class="service-list">
      <div class="uk-container">
        <div>
          <div
            class="service-list-header uk-margin-small-top uk-margin-small-bottom"
          >
            <div class="uk-child-width-1-2 uk-child-width-1-4@m" uk-grid>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations/nearby'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-primary"
                  >in der Umgebung</a
                >
              </div>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-primary"
                  >Alle</a
                >
              </div>
              <div class="uk-margin-small-top">
                <a
                  [routerLink]="'/destinations/cities'"
                  class="uk-button uk-button-small uk-width-1-1 uk-button-default"
                  >nach Städten</a
                >
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="service-list-filter">
            <div uk-grid="" class="uk-grid">
              <div class="uk-width-1-2@s uk-width-1-6@m uk-first-column">
                <span>Liste filtern</span>
              </div>
              <div class="uk-width-1-2@s uk-width-1-6@m">
                <select
                  class="service-list-select"
                  data-service-list-filters="1"
                  (change)="applyFilters()"
                  [(ngModel)]="selectedAlphabet"
                >
                  <option value="all">Alle</option>
                  <option value="a-c">A-C</option>
                  <option value="d-f">D-F</option>
                  <option value="g-i">G-I</option>
                  <option value="j-m">J-M</option>
                  <option value="n-s">N-S</option>
                  <option value="t-z">T-Z</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="uk-container uk-accordion"
        id="accordion"
        role="tablist"
        aria-multiselectable="false"
        uk-accordion=""
        uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-medium; delay: 150"
      >
        <div *ngFor="let city of cities">
          <a href="#" data-chars="a-c" class="uk-accordion-title">
            <h4>
              {{
                city.cityalpha ? city.cityalpha : "Städteübergreifende Angebote"
              }}
            </h4>
          </a>

          <div class="uk-accordion-content" hidden="">
            <ul
              class="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid uk-grid-stack"
              uk-grid=""
            >
              <li
                *ngFor="let presentation of city.presentations"
                style="background-color: {{
                  presentation.category
                    ? presentation.category.color
                    : '#e9edf7'
                }}"
              >
                <div class="citko-li">
                  <a
                    [routerLink]="'/destinations/detail/' + presentation.uid"
                    >{{ presentation.title }}</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
