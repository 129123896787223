<div class="uk-width-1-1 uk-text-center" *ngIf="loading === true">
  <div class="uk-margin-top" uk-spinner></div>
</div>

<div class="uk-container" *ngIf="loading === false">
  <div id="main">
    <div id="content">
      <main>
        <div class="csc-frame csc-frame-default">
          <div>
            <div class="form-box uk-align-center">
              <h2>App-Einstellungen</h2>
              <form [formGroup]="pushForm" (ngSubmit)="onSubmit()">
                <div class="body bg-gray">
                  <div class="uk-margin">
                    <label>
                      <input
                        type="checkbox"
                        class="uk-checkbox"
                        formControlName="pushNotifications"
                        (change)="onSubmit()"
                      />
                      Push-Nachrichten erhalten
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <!-- ###LOGIN_FORM### -->
          </div>

          <!-- END: Content of extension "felogin", plugin "tx_felogin_pi1" -->
        </div>
      </main>
    </div>
  </div>
</div>
