import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { ApiResponse } from '../shared/api-response';
import { News } from '../shared/news';
import { Presentation } from '../shared/presentation';
import { Interest } from '../shared/interest';
import { Agegroup } from '../shared/agegroup';
import { Token } from '../shared/token';
declare var UIkit: any;

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {}

  getNews(): Observable<ApiResponse<News[]>> {
    return this.http
      .get<ApiResponse<News[]>>(`${environment.apiUrl}/news/`, {
        headers: this.reqHeader,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getPresentations(): Observable<ApiResponse<Presentation[]>> {
    return this.http
      .get<ApiResponse<Presentation[]>>(
        `${environment.apiUrl}/presentations/`,
        {
          headers: this.reqHeader,
        }
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  getInterests(): Observable<ApiResponse<Interest[]>> {
    return this.http
      .get<ApiResponse<Interest[]>>(`${environment.apiUrl}/interests/`, {
        headers: this.reqHeader,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getAgegroups(): Observable<ApiResponse<Agegroup[]>> {
    return this.http
      .get<ApiResponse<Agegroup[]>>(`${environment.apiUrl}/agegroups/`, {
        headers: this.reqHeader,
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  getToken(token: string): Observable<Token> {
    const requestBody = {
      token: token
    };
    return this.http
      .post<Token>(`${environment.apiUrl}/token/get/`, requestBody)
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteToken(token: string): Observable<Token> {
    const requestBody = {
      token: token
    };
    return this.http
      .post<Token>(`${environment.apiUrl}/token/delete/`, requestBody)
      .pipe(retry(1), catchError(this.handleError));
  }

  updateToken(token: string, userId: number): Observable<Token> {
    const requestBody = {
      token: token,
      user: userId
    };
    return this.http
      .post<Token>(`${environment.apiUrl}/token/update/`, requestBody)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendToken(token: string, userId: number): Observable<any> {
    const requestBodyUser = {
      token: token,
      user: userId,
    };
    const requestBody = {
      token: token,
    };

    return this.http
      .post<any>(
        `${environment.apiUrl}/token/add/`,
        userId == null ? requestBody : requestBodyUser
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  sendRating(
    destinationId: number,
    userId: number,
    rating: number
  ): Observable<ApiResponse<any>> {
    const requestBody = {
      user_id: userId,
      value: rating,
    };

    return this.http
      .post<ApiResponse<any>>(
        `${environment.apiUrl}/presentation/${destinationId}/rate/`,
        requestBody
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  addFavorite(
    userId: number,
    destinationId: number
  ): Observable<ApiResponse<any>> {
    const requestBody = {
      user_id: userId,
    };
    return this.http
      .post<ApiResponse<any>>(
        `${environment.apiUrl}/presentation/${destinationId}/bookmark/`,
        requestBody
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteFavorite(
    userId: number,
    destinationId: number
  ): Observable<ApiResponse<any>> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        user_id: userId,
      },
    };
    return this.http
      .delete<ApiResponse<any>>(
        `${environment.apiUrl}/presentation/${destinationId}/bookmark/`,
        options
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    UIkit.alert(errorMessage);
    return throwError(errorMessage);
  }
}
