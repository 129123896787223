import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestApiService } from '../../services/rest-api.service';
import { Router } from '@angular/router';
import { Presentation } from 'src/app/shared/presentation';
import { Category } from 'src/app/shared/category';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
})
export class DestinationsComponent implements OnInit {
  apiPresentations: Presentation[];
  baseUrl = environment.baseUrl;
  categories: string[];
  presentations: Presentation[];
  loading: boolean = true;

  selectedAlphabet: string;
  selectedCategory: string;
  selectedPrice: string;

  constructor(
    private restApi: RestApiService,
    private router: Router,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.restApi.getPresentations().subscribe((data) => {
      this.apiPresentations = data.items;
      this.selectedAlphabet = this.settingsService.presentationsAtoZ();
      this.selectedCategory = this.settingsService.category();
      this.selectedPrice = this.settingsService.price();

      this.categories = [];
      // this.categories.push('Special');
      this.apiPresentations.forEach((item) => {
        if (item.category && this.categories.indexOf(item.category.title) == -1)
          this.categories.push(item.category.title);
      });
      this.categories.sort((a, b) => {
        return a > b ? 1 : b > a ? -1 : 0;
      });

      this.applyFilters();
      this.loading = false;
    });
  }

  applyFilters(): void {
    this.settingsService.presentationsAtoZ(this.selectedAlphabet);
    this.settingsService.category(this.selectedCategory);
    this.settingsService.price(this.selectedPrice);
    this.presentations = [];

    this.apiPresentations.forEach((item) => {
      var match: boolean = true;

      item.imagesDisp = Object.values(item.images);

      // Buchstaben
      if (this.selectedAlphabet !== 'all') {
        var alphabetArray: Array<string> = this.selectedAlphabet.split('-');
        if (
          item.title.charAt(0).toLowerCase() < alphabetArray[0] ||
          item.title.charAt(0).toLowerCase() > alphabetArray[1]
        )
          match = false;
      }

      // Categories
      if (this.selectedCategory !== 'all') {
        if (item.category && item.category.title !== this.selectedCategory)
          match = false;
        if (!item.category && this.selectedCategory !== 'Special')
          match = false;
      }

      // Price
      if (this.selectedPrice !== 'all') {
        if (
          item.category &&
          item.category.fullhalf !== parseInt(this.selectedPrice)
        )
          match = false;
        if (
          !item.category &&
          item.fullhalfspecial !== parseInt(this.selectedPrice)
        )
          match = false;
      }

      if (match) this.presentations.push(item);
    });
  }
}
