import { Directive, HostListener } from '@angular/core';
declare var UIkit: any;

@Directive({
  selector: '[appCloseMenuOnClick]',
})
export class CloseMenuOnClickDirective {
  numberOfClicks = 0;

  @HostListener('click', ['$event.target'])
  onClick(element) {
    UIkit.offcanvas('#sidenav').hide();
  }
}
