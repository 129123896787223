<div class="uk-container">
  <div id="main">
    <div id="content">
      <main>
        <div id="c1904" class="csc-frame csc-frame-default">
          <div class="tx-felogin-pi1">
            <div class="form-box uk-align-center" id="login-box">
              <form
                [formGroup]="passwordForm"
                (ngSubmit)="onSubmit()"
                *ngIf="!emailSend"
              >
                <div class="body bg-gray">
                  <h2>Passwort vergessen?</h2>

                  <div>
                    Bitte gebt eure E-Mailadresse ein. Ihr erhaltet dann
                    umgehend Anweisungen zum Zurücksetzen eures Passworts auf
                    diese
                  </div>
                  <br />

                  <div class="uk-margin">
                    <label for="username" class="uk-form-label"
                      >E-Mail-Adresse</label
                    >
                    <input
                      type="email"
                      formControlName="email"
                      id="email"
                      name="email"
                      value=""
                      class="uk-input"
                      placeholder="E-Mail-Adresse"
                      [class.uk-form-danger]="
                        passwordForm.get('email').invalid &&
                        (passwordForm.get('email').dirty ||
                          passwordForm.get('email').touched)
                      "
                    />
                    <div
                      *ngIf="
                        passwordForm.get('email').invalid &&
                        (passwordForm.get('email').dirty ||
                          passwordForm.get('email').touched)
                      "
                      class="uk-text-danger"
                    >
                      Bitte gültige Email-Adresse eingeben
                    </div>
                  </div>
                </div>

                <div class="footer">
                  <button
                    [disabled]="
                      loading ||
                      (passwordForm.get('email').invalid &&
                        (passwordForm.get('email').dirty ||
                          passwordForm.get('email').touched))
                    "
                    class="uk-button uk-button-primary"
                  >
                    <span *ngIf="loading" uk-spinner></span>
                    Passwort zurücksetzen
                  </button>

                  <!--###FORGOTP_VALID###-->
                  <p>
                    <a [routerLink]="'/account/login'"
                      >Zurück zum Anmeldeformular</a
                    >
                  </p>
                </div>
              </form>
            </div>
            <!-- ###LOGIN_FORM### -->
          </div>

          <div
            class="form-box uk-align-center"
            id="login-box"
            *ngIf="emailSend"
          >
            <div class="body bg-gray">
              <h2>Passwort vergessen?</h2>

              <div>
                Es wurde eine E-Mail mit einem Link zum Zurücksetzen des
                Passworts an die angegebene E-Mailadresse gesendet. Solltet ihr
                keine E-Mail erhalten haben, wurde die E-Mailadresse oder das
                Konto nicht gefunden.
              </div>
              <br />

              <p>
                <a [routerLink]="'/account/login'"
                  >Zurück zum Anmeldeformular</a
                >
              </p>
            </div>
          </div>

          <!-- END: Content of extension "felogin", plugin "tx_felogin_pi1" -->
        </div>
      </main>
    </div>
  </div>
</div>
