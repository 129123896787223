import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { RestApiService } from './rest-api.service';

declare var UIkit: any;

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private authenticationService: AuthenticationService,
    private restApi: RestApiService
  ) {
    /* this.angularFireMessaging.messages.subscribe(
      (msgings: AngularFireMessaging) => {
        msgings.onMessage = msgings.onMessage.bind(msgings);
        //msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
      }
    ); */
  }

  getToken() {
    return this.angularFireMessaging.getToken;
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe((token) => {
      this.authenticationService.currentUserData.subscribe((userData) => {
        this.restApi
          .sendToken(
            token,
            userData
              ? this.authenticationService.currentUserValue.user_id
              : null
          )
          .subscribe();
      });
    });
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((msg: any) => {
      this.currentMessage.next(msg);
      //console.log(msg);
      if (msg !== null)
        UIkit.modal.alert(
          `<h3>${msg.notification.title}</h3><div>${msg.notification.body}</div>`
        );
    });
  }
}
