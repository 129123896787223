<div class="uk-container">
  <div uk-sticky="offset: 70" class="uk-margin-small-bottom appsubnav">
    <div uk-grid>
      <div>
        <button class="uk-button uk-button-default" appLogoutButton>
          <span i18n="logout">Logout</span>
        </button>
      </div>
      <div class="uk-width-expand uk-hidden@m">
        <select
          class="uk-select"
          (change)="changeAccountPage()"
          [(ngModel)]="currentAccountPage"
        >
          <option value="/account">Startseite</option>
          <option value="/account/profile">Profil</option>
          <option value="/account/favorites">Favoriten</option>
          <option value="/account/mycard">MyCard</option> 
        </select>
      </div>
    </div>
  </div>
  <div id="main">
    <div id="content">
      <main>
        <div uk-grid="" class="profile-container uk-flex">
          <div
            class="profile-left-container uk-visible@m uk-width-1-5@m uk-flex-last uk-flex-first@m"
          >
            <a [routerLink]="'/account/profile'"> Profil </a>
            <a [routerLink]="'/account/favorites'"> Favoriten </a>
            <a [routerLink]="'/account/mycard'" class="active"> MyCard </a> 
          </div>

          <div class="profile-right-container femanager_edit uk-width-4-5@m">
            <p>
              Über den MyCard-Bereich unseres Anbieters feratel könnt ihr euch
              mit eurer Kartennummer registrieren und anschließend eure
              Card-Nutzungen einsehen.
            </p>

            <p>
              <a
                href="https://card-webshop.feratel.com/rtc01/default/index.jsp?mode=mycard#p:mycard-login"
                target="_blank"
                class="uk-button uk-button-primary"
                >zu MyCard</a
              > 
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
