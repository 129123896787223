import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { RestApiService } from 'src/app/services/rest-api.service';

declare var UIkit: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  pushForm: UntypedFormGroup;
  loading = false;
  currentToken: string;
  userId: number = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messagingService: MessagingService,
    private restApi: RestApiService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.authenticationService.currentUserData.subscribe((userData) => {
      if (userData) this.userId = this.authenticationService.currentUserValue.user_id;
    });

    this.aktualisiereDaten();
  }

  aktualisiereDaten() {
    
    if (Notification.permission === 'granted') {
      this.messagingService.getToken().subscribe((token) => {
        //console.log("currentToken: " + token);
        this.currentToken = token;
        this.restApi.getToken(token).subscribe((result) => {
          //console.log("serverToken " + JSON.stringify(result));
          this.buildForm(result.success);
        }, () => {
          //console.log("buildform 3 ");
          this.buildForm(false);
        });
      }, () => {
        //console.log("buildform 2 ");
        this.buildForm(false);
      });
    } else {
      //console.log("buildform 1 ");
      this.buildForm(false);
    }
  }

  buildForm(pushNotifiations: boolean) {
    this.pushForm = this.formBuilder.group({
      pushNotifications: pushNotifiations,
    });
    this.loading = false;
  }

  get f() {
    return this.pushForm.controls;
  }

  onSubmit(): void {
    if (this.f.pushNotifications.value) {
      if (Notification.permission !== 'granted') {
        this.messagingService.requestPermission();
      } else {
        this.restApi.sendToken(this.currentToken, this.userId).subscribe((result) => {
          this.aktualisiereDaten();
          this.settingsUpdated();
        });
      }
    } else {
      this.restApi.deleteToken(this.currentToken).subscribe((result) => {
        this.aktualisiereDaten();
        this.settingsUpdated();
      });
    };
    
  }

  settingsUpdated(): void {
    UIkit.notification({
      message: '<span uk-icon="icon: check"></span> Einstellungen aktualisiert',
      status: 'success',
    });
  }
}
