import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ReadWepPageService } from 'src/app/services/read-wep-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements AfterViewInit {

  loading: boolean = true;
  htmlContent: string = '';
  h1: string = '';
  @ViewChild('websitecontent') contentElement: ElementRef;

  constructor(private webpage: ReadWepPageService, private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.getRawData();
  }

  getRawData() {
    this.webpage
      .getRawData(environment.privacyUrl)
      .subscribe(
        data => {
          let originalHtml: string = data;
          var parser = new DOMParser();
         
          var htmlDoc = parser.parseFromString(originalHtml, 'text/html');
          var htmlContent = htmlDoc.querySelector("#content main");
          //this.h1 = htmlDoc.querySelector("h1:first-child").innerHTML;

          var newEl = this.renderer.createElement("main");
          this.renderer.setProperty(newEl, 'innerHTML', htmlContent.innerHTML);
          this.renderer.appendChild(this.contentElement.nativeElement, newEl);

          this.loading = false;
        },
        error => console.log(error)
      );
  }


}
