import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Agegroup } from 'src/app/shared/agegroup';
import { Interest } from 'src/app/shared/interest';
import { UserData } from 'src/app/shared/user-data';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  currentAccountPage: string = '/account/profile';
  loading: boolean = true;
  profileForm: UntypedFormGroup;
  userData: UserData;
  apiInterests: Array<Interest>;
  apiAgegroups: Array<Agegroup>;
  submitted: boolean = false;
  deleteClicked: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private restApi: RestApiService,
    private router: Router
  ) {}

  get f() {
    return this.profileForm.controls;
  }

  ngOnInit(): void {
    this.loadUser();
    forkJoin([this.restApi.getInterests(), this.restApi.getAgegroups()])
      .pipe(
        map(([interests, agegroups]) => {
          // forkJoin returns an array of values, here we map those values to an object
          return { interests, agegroups };
        })
      )
      .subscribe((data) => {
        this.apiInterests = data.interests.items;
        this.apiAgegroups = data.agegroups.items;

        this.buildForm();

        this.loading = false;
      });
  }

  buildForm() {
    this.profileForm = this.formBuilder.group({
      cardnr: [this.userData.user.cardnr],
      firstName: [this.userData.user.firstName, Validators.required],
      lastName: [this.userData.user.lastName, Validators.required],
      email: [
        this.userData.user.email,
        [Validators.required, Validators.email],
      ],
      city: [this.userData.user.city],
      agegroup: [
        this.userData.user.agegroup ? this.userData.user.agegroup.uid : null,
      ],
      interests: this.formBuilder.group({}),
    });

    const checkboxes = <UntypedFormGroup>this.profileForm.get('interests');
    this.apiInterests.forEach((option: any) => {
      checkboxes.addControl(
        option.title,
        new UntypedFormControl(this.interestIsSelected(option.uid))
      );
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.profileForm.invalid) {
      this.getFormValidationErrors();
      return;
    }

    this.submitted = true;

    var submitInterestes: Array<number> = this.getInterestsSubmit();

    this.authenticationService
      .updateProfile(
        this.userData.user.uid,
        this.userData.user.username,
        this.f.firstName.value,
        this.f.lastName.value,
        this.f.email.value,
        this.f.city.value,
        this.f.cardnr.value,
        this.f.agegroup.value,
        submitInterestes,
        this.userData.user.source,
        this.userData.user.sourceIdentifier
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.authenticationService.refreshUserData().subscribe((data) => {
            this.loadUser();
            this.buildForm();
          });
          this.submitted = false;
        },
        (error) => {
          //this.alertService.error(error);
          this.submitted = false;
        }
      );
  }

  onDeleteClick(e) {
    e.preventDefault();

    if (this.deleteClicked) {
      return;
    }

    this.deleteClicked = true;

    this.authenticationService
      .deleteProfile(this.userData.user.uid)
      .pipe(first())
      .subscribe(
        (data) => {
          this.authenticationService.logout();
          this.deleteClicked = false;
          this.router.navigate(['/account/login']);
        },
        (error) => {
          // this.alertService.error(error);
          this.deleteClicked = false;
        }
      );
  }

  loadUser() {
    this.authenticationService.currentUserData.subscribe(
      (value) => (this.userData = value)
    );
  }

  interestIsSelected(uid: number) {
    var result: boolean = false;
    Object.values(this.userData.user.interests).forEach((item) => {
      if (parseInt(item.uid) === uid) result = true;
    });
    return result;
  }

  getInterestsSubmit(): Array<number> {
    var newValues = [];
    Object.entries(this.f.interests.value).forEach((item) => {
      this.apiInterests.forEach((apiItem) => {
        if (apiItem.title === item[0] && item[1] === true) {
          newValues.push(apiItem.uid);
        }
      });
    });
    return newValues;
  }

  changeAccountPage() {
    const selectedAccountPage: string = this.currentAccountPage;
    this.currentAccountPage = '/account/profile';
    this.router.navigate([selectedAccountPage]);
  }

  getFormValidationErrors() {
    Object.keys(this.profileForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.profileForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
