import { Component, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MessagingService } from './services/messaging.service';
import { filter, map } from 'rxjs/operators';

declare var UIkit: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Ruhrtopcard';

  constructor(
    private swUpdate: SwUpdate,
    private messagingService: MessagingService
  ) {}

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      // ...
      const updatesAvailable = this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      );

      updatesAvailable.subscribe(() => {
        UIkit.modal
          .confirm('Eine neue Version der App liegt vor. Jetzt laden?')
          .then(() => {
            window.location.reload();
          });
      });
    }
    if (Notification.permission !== 'granted') {
      var self = this;
      UIkit.modal
        .confirm(
          'Wir würden Euch gerne auf dem Laufenden halten. Bitte stimmt dazu den Benachrichtigungen zu.',
          {
            labels: { ok: 'Berechtigung erteilen', cancel: 'Nein danke' },
          }
        )
        .then(
          () => {
            self.messagingService.requestPermission();
          },
          () => {
            // nothing todo (kein Push erwünscht)
          }
        );
    }
    this.messagingService.receiveMessage();
  }
}
