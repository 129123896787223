<div class="uk-container">
  <div id="main">
    <div id="content">
      <main>
        <div id="c1904" class="csc-frame csc-frame-default">
          <div class="tx-felogin-pi1">
            <div class="form-box uk-align-center" id="login-box">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="body bg-gray">
                  <h2>Einloggen</h2>

                  <div>
                    Gebt hier eure E-Mail Adresse und euer Passwort ein um euch
                    im persönlichen Bereich anzumelden:
                  </div>
                  <br />

                  <div class="uk-margin">
                    <label for="username" class="uk-form-label"
                      >E-Mail-Adresse</label
                    >
                    <input
                      type="email"
                      formControlName="username"
                      id="username"
                      name="username"
                      value=""
                      class="uk-input"
                      placeholder="E-Mail-Adresse"
                    />
                  </div>
                  <div class="uk-margin">
                    <label for="password" class="uk-form-label">Kennwort</label>
                    <input
                      type="password"
                      formControlName="password"
                      id="password"
                      name="password"
                      value=""
                      class="uk-input"
                      placeholder="Kennwort"
                      data-rsa-encryption=""
                    />
                  </div>
                </div>

                <div class="footer">
                  <button
                    [disabled]="loading"
                    class="uk-button uk-button-primary"
                  >
                    <span *ngIf="loading" uk-spinner></span>
                    Login
                  </button>

                  

                  <div class="uk-margin-top">
                    <p>
                      <a [routerLink]="'/account/password'"
                        >Passwort vergessen?</a
                      >
                    </p>
                    <p>
                      <a [routerLink]="'/account/register'"
                        >Zur Registrierung</a
                      >
                    </p>
                  </div>

                  <div class="sociallogin-container uk-margin-top" *ngIf="socialLoading">
                    <p><span uk-spinner="ratio: 0.75"></span></p>
                  </div>
                  <div class="sociallogin-container uk-margin-top" *ngIf="!socialLoading">
                    <hr />
                    <p>Login mit</p>
                    <div
                        *ngIf="socialError"
                        class="uk-text-danger"
                      >
                        Der Login war nicht möglich<br>{{ socialError }}
                    </div>
                    <a class="uk-button facebook" (click)="loginWithFacebook()">
                      <span uk-icon="icon: facebook" class="uk-icon"></span>
                      Facebook 
                    </a>
                    <asl-google-signin-button class="uk-button google" logo_alignment="center" type="standard" text="signin_with"></asl-google-signin-button>
                    <div class="uk-clearfix"></div>
                    <hr />
                  </div>
                </div>
              </form>
            </div>
            <!-- ###LOGIN_FORM### -->
          </div>

          <!-- END: Content of extension "felogin", plugin "tx_felogin_pi1" -->
        </div>
      </main>
    </div>
  </div>
</div>
