import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { User } from '../shared/user';
import { environment } from 'src/environments/environment';
import { RestApiService } from './rest-api.service';
import { UserData } from '../shared/user-data';
import { Agegroup } from '../shared/agegroup';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentUserDataSubject: BehaviorSubject<UserData>;
  private dontAuthorizeNextRequestSubject: BehaviorSubject<Boolean>;
  public currentUser: Observable<User>;
  public currentUserData: Observable<UserData>;
  public dontAuthorizeNextRequest: Observable<Boolean>;

  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private restApiService: RestApiService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUserDataSubject = new BehaviorSubject<UserData>(
      JSON.parse(localStorage.getItem('userData'))
    );
    this.dontAuthorizeNextRequestSubject = new BehaviorSubject<Boolean>(false);
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserData = this.currentUserDataSubject.asObservable();
    this.dontAuthorizeNextRequest =
      this.dontAuthorizeNextRequestSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get dontAuthorize(): Boolean {
    return this.dontAuthorizeNextRequestSubject.value;
  }

  setDontAuthorize(dontAuthorize: Boolean) {
    this.dontAuthorizeNextRequestSubject.next(dontAuthorize);
  }

  setSocialContextPlatform(platform) {
    localStorage.setItem('socialLoginPlatform', platform);
  }
  setSocialContextProcess(process) {
    localStorage.setItem('socialLoginProcess', process);
  }

  getSocialContextPlatform(): string {
    return localStorage.getItem('socialLoginPlatform');
  }
  getSocialContextProcess(): string {
    return localStorage.getItem('socialLoginProcess');
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/auth/`,
        { username, password },
        {
          headers: this.reqHeader,
        }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.jwt_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  socialLogin(username: string, token: string, provider: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/socialauth/`,
        { username: username, token: token, provider: provider },
        {
          headers: this.reqHeader,
        }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.jwt_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  socialLoginValidation(userid: string, jwt: string, provider: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/socialauthvalidation/`,
        { userid: userid, jwt: jwt, provider: provider },
        {
          headers: this.reqHeader,
        }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.jwt_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  updateProfile(
    userId: number,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    city: string,
    cardnr: string,
    agegroup: number,
    interests: Array<number>,
    socialPlatform: string,
    socialToken: string
  ) {
    const postdata = {
      username: username,
      first_name: firstName,
      last_name: lastName,
      email: email,
      city: city,
      cardnr: cardnr,
      agegroup: agegroup,
      interests: interests,
      source: socialPlatform,
      source_identifier: socialToken,
    };
    //console.log(postdata);
    return this.http
      .post<any>(`${environment.apiUrl}/user/${userId}/update`, postdata)
      .pipe((result) => {
        return result;
      });
  }

  register(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    interests: Array<number>,
    newsletter: boolean,
    submitUrl: string,
    argumentsToken: string,
    requestContent: string,
    trustedPropertiesContent: string
  ) {
    const formData = new FormData();
    formData.append('tx_femanager_pi1[__referrer][@extension]', 'Femanager');
    formData.append('tx_femanager_pi1[__referrer][@vendor]', 'In2code');
    formData.append('tx_femanager_pi1[__referrer][@controller]', 'New');
    formData.append('tx_femanager_pi1[__referrer][@action]', 'new');
    formData.append('tx_femanager_pi1[__referrer][arguments]', argumentsToken);
    formData.append('tx_femanager_pi1[__referrer][@request]', requestContent);
    formData.append(
      'tx_femanager_pi1[__trustedProperties]',
      trustedPropertiesContent
    );
    formData.append('tx_femanager_pi1[user][username]', email);
    formData.append('tx_femanager_pi1[user][firstName]', firstName);
    formData.append('tx_femanager_pi1[user][lastName]', lastName);
    formData.append('tx_femanager_pi1[user][password]', password);
    formData.append('tx_femanager_pi1[password_repeat]', password);
    formData.append('tx_femanager_pi1[user][terms]', 'Terms and Conditions');
    if (newsletter)
      formData.append('tx_femanager_pi1[user][newsletter]', 'Newsletter');
    interests.forEach((element) => {
      formData.append(
        'tx_femanager_pi1[user][interests][]',
        element.toString()
      );
    });

    //const formData = new FormData();
    //formData.append("tx_felogin_pi1[forgot_hash]", token);
    //formData.append("tx_felogin_pi1[forgot_email]", email);

    const requestOptions: Object = {
      /* other options here */
      withCredentials: true,
      responseType: 'text',
    };

    //console.log(postdata);
    return this.http.post<any>(`${submitUrl}`, formData, requestOptions);
  }

  resetPassword(email: string, token: string) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded;'
    );

    const body =
      'tx_felogin_pi1[forgot_hash]=' +
      token +
      '&tx_felogin_pi1[forgot_email]=' +
      email +
      '&submit=' +
      'Passwort zurücksetzen';

    //const formData = new FormData();
    //formData.append("tx_felogin_pi1[forgot_hash]", token);
    //formData.append("tx_felogin_pi1[forgot_email]", email);

    const requestOptions: Object = {
      /* other options here */
      headers: headers,
      withCredentials: true,
      responseType: 'text',
    };

    //console.log(postdata);
    return this.http.post<any>(
      `${environment.passwordUrl}`,
      encodeURI(body),
      requestOptions
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

    localStorage.removeItem('userData');
    this.currentUserDataSubject.next(null);
  }

  refreshUserData(): Observable<UserData> {
    localStorage.removeItem('userData');
    this.currentUserDataSubject.next(null);

    const uid = this.currentUserValue.user_id;
    return this.http
      .get<UserData>(`${environment.apiUrl}/user/${uid}/details/`, {
        headers: this.reqHeader,
      })
      .pipe(
        map((userData) => {
          if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
            this.currentUserDataSubject.next(userData);
          }

          return userData;
        })
      );
  }

  deleteProfile(
    userId: number
  ) {
    return this.http
      .delete<any>(`${environment.apiUrl}/user/${userId}/delete`)
      .pipe((result) => {
        return result;
      });
  }
}
