import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first } from 'rxjs/operators';
import { ReadWepPageService } from 'src/app/services/read-wep-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  loading: boolean = false;
  emailSend: boolean = false;
  submitted: boolean = false;
  returnUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private webpage: ReadWepPageService,
    private alertService: AlertService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/account']);
    }
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/account';
  }

  get f() {
    return this.passwordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    this.loading = true;

    this.webpage.getRawData(environment.passwordUrl).subscribe(
      (data) => {
        let originalHtml: string = data;
        var parser = new DOMParser();

        var htmlDoc = parser.parseFromString(originalHtml, 'text/html');
        var token = htmlDoc
          .querySelector('input[name="tx_felogin_pi1[forgot_hash]"]')
          .getAttribute('value');

        this.authenticationService
          .resetPassword(this.f.email.value, token)
          .subscribe((data) => {
            this.loading = false;
            this.emailSend = true;
          });
      },
      (error) => console.log(error)
    );
  }
}
