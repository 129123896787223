import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor() {}

  locationRadius(locationRadius: string = null) {
    if (locationRadius !== null)
      localStorage.setItem('locationRadius', locationRadius);
    locationRadius =
      localStorage.getItem('locationRadius') !== null
        ? localStorage.getItem('locationRadius')
        : this.locationRadius('all');
    return locationRadius;
  }

  presentationsAtoZ(presentationsAtoZ: string = null) {
    if (presentationsAtoZ !== null)
      localStorage.setItem('presentationsAtoZ', presentationsAtoZ);
    presentationsAtoZ =
      localStorage.getItem('presentationsAtoZ') !== null
        ? localStorage.getItem('presentationsAtoZ')
        : this.presentationsAtoZ('all');
    return presentationsAtoZ;
  }

  citiesAtoZ(citiesAtoZ: string = null) {
    if (citiesAtoZ !== null) localStorage.setItem('citiesAtoZ', citiesAtoZ);
    citiesAtoZ =
      localStorage.getItem('citiesAtoZ') !== null
        ? localStorage.getItem('citiesAtoZ')
        : this.citiesAtoZ('all');
    return citiesAtoZ;
  }

  category(category: string = null) {
    if (category !== null) localStorage.setItem('category', category);
    category =
      localStorage.getItem('category') !== null
        ? localStorage.getItem('category')
        : this.category('all');
    return category;
  }

  price(price: string = null) {
    if (price !== null) localStorage.setItem('price', price);
    price =
      localStorage.getItem('price') !== null
        ? localStorage.getItem('price')
        : this.price('all');
    return price;
  }
}
