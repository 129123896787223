<div class="uk-width-1-1 uk-text-center" *ngIf="loading === true">
  <div class="uk-margin-top" uk-spinner></div>
</div>

<div class="uk-container uk-margin-top" *ngIf="loading === false">
  <div class="uk-animation-fade">
    <h1 i18n="Willkommen zu über 140 Erlebnisgeschichten!">
      Willkommen zu über 140 Erlebnisgeschichten!
    </h1>
    <p>
      Egal ob großes Bauchkribbeln, echtes Wildlife oder pure Gänsehaut - über
      140 Ausflugsziele warten darauf von euch entdeckt zu werden. Lasst euch
      inspirieren und plant eure Entdeckungstouren durchs Ruhrgebiet. Und falls
      ihr nicht mehr wisst, welche Ziele ihr schon besucht habt: mir eurem
      eigenen Profil behaltet ihr stets den Überblick!
    </p>
    <p>
      Für mehr Informationen rund um die RUHR.TOPCARD besucht
      <a href="https://www.ruhrtopcard.de" target="_blank">unsere Webseite</a>.
    </p>
    <p>
      <a [routerLink]="['/destinations/nearby']">
        Hier geht's zu den aktuellen Ausflugszielen der RUHR.TOPCARD
      </a>
    </p>
  </div>
  <h2 i18n="Aktuelles" class="uk-animation-fade">Aktuelles</h2>
  <div class="news">
    <div class="news-list-view-standard layout-1">
      <ul
        uk-scrollspy="target: > li; cls: uk-animation-slide-bottom-medium; delay: 150"
      >
        <li *ngFor="let newsItem of newsItems">
          <span class="article articletype-0">
            <a
              [routerLink]="
                '/news/' + hashCode(newsItem.datetime + newsItem.title)
              "
            >
              <div class="uk-width-1-1" uk-grid>
                <div class="uk-width-1-1 uk-width-3-5@s">
                  <div class="headline uk-margin-bottom">
                    {{ newsItem.title }}
                  </div>
                  <div itemprop="teaser" class="teaser">
                    {{ newsItem.teaser }}
                  </div>
                </div>
                <div
                  class="uk-width-1-1 uk-width-2-5@s uk-margin-top uk-text-right@s"
                >
                  <img
                    class="uk-margin-small-right"
                    src="/assets/img/calendar.png"
                    alt=""
                    width="41"
                    height="48"
                  />
                  {{ newsItem.datetime | date: "dd.MM.yyyy" }}
                </div>
              </div>
            </a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
