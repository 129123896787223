<div class="uk-container">
  <!--/noindex-->
  <div uk-sticky="offset: 70" class="uk-margin-small-bottom appsubnav">
    <div uk-grid>
      <div>
        <button class="uk-button uk-button-default" appLogoutButton>
          <span i18n="logout">Logout</span>
        </button>
      </div>
      <div class="uk-width-expand uk-hidden@m">
        <select
          class="uk-select"
          (change)="changeAccountPage()"
          [(ngModel)]="currentAccountPage"
        >
          <option value="/account">Startseite</option>
          <option value="/account/profile">Profil</option>
          <option value="/account/favorites">Favoriten</option>
          <option value="/account/mycard">MyCard</option>
        </select>
      </div>
    </div>
  </div>
  <div id="main">
    <div id="content">
      <main>
        <!--  CONTENT ELEMENT, uid:1905/list [begin] -->
        <div id="c1905" class="csc-frame csc-frame-default">
          <!--  Plugin inserted: [begin] -->

          <div class="tx-dvd-benutzermanager">
            <div uk-grid class="profile-container uk-flex">
              <div
                class="profile-left-container uk-visible@m uk-width-1-5@m uk-flex-last uk-flex-first@m"
              >
                <a [routerLink]="'/account/profile'"> Profil </a>
                <a [routerLink]="'/account/favorites'"> Favoriten </a>
                <a [routerLink]="'/account/mycard'"> MyCard </a>
              </div>
              <div class="profile-right-container uk-width-4-5@m">
                Hallo {{ userData.user.firstName }}!

                <br /><br />
                In diesem Bereich könnt ihr euer persönliches Profil erstellen,
                eure Interessen angeben, Favoriten verwalten und eure
                Card-Nutzung im Auge behalten.
              </div>
            </div>
          </div>
          <!--  Plugin inserted: [end] -->
        </div>
        <!--  CONTENT ELEMENT, uid:1905/list [end] -->
      </main>
    </div>
  </div>

  <!--noindex-->
</div>
