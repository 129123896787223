<div class="uk-width-1-1 uk-text-center" *ngIf="loading === true">
  <div class="uk-margin-top" uk-spinner></div>
</div>

<div class="uk-container" *ngIf="loading === false">
  <div id="main">
    <div id="content">
      <main>
        <div id="c1904" class="csc-frame csc-frame-default">
          <div class="tx-felogin-pi1">
            <div class="form-box uk-align-center" id="login-box">
              <form
                [formGroup]="registerForm"
                (ngSubmit)="onSubmit()"
                *ngIf="!registrationSend"
              >
                <div class="body bg-gray">
                  <h2>Neues Benutzerprofil erstellen</h2>

                  <div class="uk-margin">
                    <label for="email" class="uk-form-label"
                      >E-Mail-Adresse <span>*</span></label
                    >
                    <div class="uk-form-controls">
                      <input
                        type="email"
                        formControlName="email"
                        id="email"
                        name="email"
                        value=""
                        class="uk-input"
                        placeholder="E-Mail-Adresse"
                        [class.uk-form-danger]="
                          registerForm.get('email').invalid &&
                          (registerForm.get('email').dirty ||
                            registerForm.get('email').touched)
                        "
                      />
                      <div
                        *ngIf="
                          registerForm.get('email').invalid &&
                          (registerForm.get('email').dirty ||
                            registerForm.get('email').touched)
                        "
                        class="uk-text-danger"
                      >
                        Bitte gültige Email-Adresse eingeben
                      </div>
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label for="firstName" class="uk-form-label">Vorname</label>
                    <div class="uk-form-controls">
                      <input
                        type="text"
                        formControlName="firstName"
                        id="firstName"
                        name="firstName"
                        class="uk-input"
                        placeholder="Vorname"
                      />
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label for="lastName" class="uk-form-label">Nachname</label>
                    <div class="uk-form-controls">
                      <input
                        type="text"
                        formControlName="lastName"
                        id="lastName"
                        name="lastName"
                        class="uk-input"
                        placeholder="Nachname"
                      />
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label for="password" class="uk-form-label"
                      >Passwort <span>*</span></label
                    >
                    <div class="uk-form-controls">
                      <input
                        type="password"
                        formControlName="password"
                        id="password"
                        name="password"
                        value=""
                        class="uk-input"
                        placeholder="Passwort"
                        [class.uk-form-danger]="
                          registerForm.get('password').invalid &&
                          (registerForm.get('password').dirty ||
                            registerForm.get('password').touched)
                        "
                      />
                      <div
                        *ngIf="
                          registerForm.get('password').invalid &&
                          (registerForm.get('password').dirty ||
                            registerForm.get('password').touched)
                        "
                        class="uk-text-danger"
                      >
                        Bitte Passwort eingeben
                      </div>
                    </div>
                  </div>

                  <div class="uk-margin">
                    <label for="confirmPassword" class="uk-form-label"
                      >Passwort wiederholen <span>*</span></label
                    >
                    <div class="uk-form-controls">
                      <input
                        type="password"
                        formControlName="confirmPassword"
                        id="confirmPassword"
                        name="confirmPassword"
                        value=""
                        class="uk-input"
                        placeholder="Passwort"
                        [class.uk-form-danger]="
                          registerForm.get('confirmPassword').invalid &&
                          (registerForm.get('confirmPassword').dirty ||
                            registerForm.get('confirmPassword').touched)
                        "
                      />
                      <div
                        *ngIf="
                          registerForm.get('confirmPassword').invalid &&
                          (registerForm.get('confirmPassword').dirty ||
                            registerForm.get('confirmPassword').touched)
                        "
                        class="uk-text-danger"
                      >
                        Die Passwörter stimmen nicht überein
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="femanager_fieldset femanager_interests uk-margin"
                  formGroupName="interests"
                >
                  <label class="uk-form-label"> Interessen </label>

                  <div class="uk-child-width-1-2@s uk-grid-collapse" uk-grid>
                    <div
                      *ngFor="
                        let item of registerForm.get('interests').value
                          | keyvalue
                      "
                    >
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          [formControlName]="item.key"
                          [value]="item.value"
                        />
                        {{ item.key }}
                      </label>
                    </div>
                  </div>
                </div>

                <!-- <div class="femanager_fieldset femanager_agegroup uk-margin">
                  <label class="uk-form-label"> Altersgruppe </label>
                  <div uk-grid="" class="uk-grid">
                    <div>
                      <label *ngFor="let item of apiAgegroups">
                        <input
                          class="uk-radio"
                          formControlName="agegroup"
                          [value]="item.uid"
                          name="agegroup"
                          type="radio"
                        />
                        {{ item.title }}
                      </label>
                    </div>
                  </div>
                </div> -->

                <div
                  class="femanager_fieldset femanager_terms uk-margin uk-margin-large-top"
                >
                  <label>
                    <input
                      type="checkbox"
                      class="uk-checkbox"
                      formControlName="privacy"
                    />
                    Ich habe die
                    <a [routerLink]="'/privacy'">Datenschutzerklärung</a>
                    gelesen *
                  </label>
                </div>

                <div class="femanager_fieldset femanager_terms uk-margin">
                  <label>
                    <input
                      type="checkbox"
                      class="uk-checkbox"
                      formControlName="newsletter"
                    />
                    Ich bin damit einverstanden über wichtige Informationen und
                    meinen Interessen entsprechenden Angeboten auf dem Laufenden
                    gehalten zu werden
                  </label>
                </div>

                <div class="footer">
                  <button
                    [disabled]="
                      loading ||
                      (registerForm.invalid &&
                        (registerForm.dirty || registerForm.touched))
                    "
                    class="uk-button uk-button-primary"
                  >
                    <span *ngIf="loading" uk-spinner></span>
                    Profil jetzt erstellen
                  </button>

                  

                  <p>
                    <a [routerLink]="'/account/login'"
                      >Zurück zum Anmeldeformular</a
                    >
                  </p>

                  <div class="sociallogin-container uk-margin-top" *ngIf="socialLoading">
                    <p><span uk-spinner="ratio: 0.75"></span></p>
                  </div>
                  <div class="sociallogin-container uk-margin-top" *ngIf="!socialLoading">
                    <hr />
                    <p>Registrierung mit</p>
                    <div
                        *ngIf="socialError"
                        class="uk-text-danger"
                      >
                        Die Registrierung war nicht möglich
                    </div>
                    <a
                      class="uk-button facebook"
                      (click)="registerWithFacebook()"
                    >
                      <span uk-icon="icon: facebook" class="uk-icon"></span>
                      Facebook
                    </a>
                    <asl-google-signin-button class="uk-button google" logo_alignment="center" type="standard" text="signin_with"></asl-google-signin-button>
                    <div class="uk-clearfix"></div>
                    <hr />
                  </div>
                </div>
              </form>
            </div>
            <!-- ###LOGIN_FORM### -->
          </div>

          <div
            class="form-box uk-align-center"
            id="login-box"
            *ngIf="registrationSend"
          >
            <div class="body bg-gray">
              <h2>Neues Benutzerprofil erstellen</h2>

              <p>Dein Profil wurde erfolgreich erstellt</p>
              <p>
                <a [routerLink]="'/account/login'">Weiter zum Login</a>
              </p>
            </div>
          </div>

          <!-- END: Content of extension "felogin", plugin "tx_felogin_pi1" -->
        </div>
      </main>
    </div>
  </div>
</div>
