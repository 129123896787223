<div class="uk-width-1-1 uk-text-center" *ngIf="loading === true">
  <div class="uk-margin-top" uk-spinner></div>
</div>

<div class="news news-single" *ngIf="loading === false">
  <div class="article uk-container">
    <div uk-sticky="offset: 70" class="uk-margin-small-bottom appsubnav">
      <button
        class="uk-button uk-button-default uk-button-small uk-margin-small-top uk-margin-small-bottom"
        appBackButton
      >
        <span uk-icon="chevron-left"></span>
      </button>
    </div>
    <h1 class="uk-text-center" itemprop="headline">{{ news.title }}</h1>
    <div class="news-text-wrap uk-text-center" itemprop="articleBody">
      <div [innerHtml]="news.bodytext" class="bodytext"></div>
    </div>
    <div
      class="_thumbnail"
      style="margin-bottom: 15px; margin-top: 30px; text-align: center"
    >
      <img
        src="{{ baseUrl + '/' + media[0].publicUrl }}"
        width="700"
        height="465"
        alt=""
        class="uk-img"
      />
    </div>
    <hr />
    <div class="uk-width-1-1 uk-margin-top uk-text-right@s">
      <img
        class="uk-margin-small-right"
        src="/assets/img/calendar.png"
        alt=""
        width="41"
        height="48"
      />
      {{ news.datetime | date: "dd.MM.yyyy" }}
    </div>
  </div>
</div>
