import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, searchValue): any {
    if (!searchValue) return value;

    return value.filter((v) => v.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
  }

}
