import { Category } from './category';

export class Presentation {
  uid: number;
  title: string;
  additionalinfo: string;
  description: string;
  category: Category;
  openinghours: string;
  address: string;
  zip: string;
  city: string;
  phone: string;
  fax: string;
  email: string;
  homepage: string;
  cityalpha: string;
  fullhalfspecial: number;
  images: any[];
  ytvideos: string;
  service: string;
  pricewo: string;
  ratingAvg: number;
  latitude: number;
  longitude: number;
  validuntil: string;
  distance: number;
  iconurl: string;
  imagesDisp: any[];
}
