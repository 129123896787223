<div class="uk-width-1-1 uk-text-center" *ngIf="loading === true">
  <div class="uk-margin-top" uk-spinner></div>
</div>

<div class="uk-container" *ngIf="loading === false">
  <div uk-sticky="offset: 70" class="uk-margin-small-bottom appsubnav">
    <div uk-grid>
      <div>
        <button class="uk-button uk-button-default" appLogoutButton>
          <span i18n="logout">Logout</span>
        </button>
      </div>
      <div class="uk-width-expand uk-hidden@m">
        <select
          class="uk-select"
          (change)="changeAccountPage()"
          [(ngModel)]="currentAccountPage"
        >
        <option value="/account">Startseite</option>
        <option value="/account/profile">Profil</option>
        <option value="/account/favorites">Favoriten</option>
        <option value="/account/mycard">MyCard</option>
        </select>
      </div>
    </div>
  </div>
  <div id="main">
    <div id="content">
      <main>
        <div uk-grid="" class="profile-container uk-flex">
          <div
            class="profile-left-container uk-visible@m uk-width-1-5@m uk-flex-last uk-flex-first@m"
          >
            <a class="active" [routerLink]="'/account/profile'">Profil </a>
            <a [routerLink]="'/account/favorites'"> Favoriten </a>
            <a [routerLink]="'/account/mycard'"> MyCard </a>
          </div>
          <div class="profile-right-container femanager_edit uk-width-4-5@m">
            <form
              [formGroup]="profileForm"
              (ngSubmit)="onSubmit()"
              name="user"
              class="form-horizontal feManagerValidation"
            >
              <fieldset>
                <div class="femanager_fieldset femanager_username uk-margin">
                  <label class="uk-form-label"> Card Nr. </label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      id="femanager_field_cardnr"
                      type="text"
                      formControlName="cardnr"
                      value=""
                    />
                  </div>
                </div>

                <div class="femanager_fieldset femanager_firstname uk-margin">
                  <label for="firstName" class="uk-form-label"> Vorname </label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      type="text"
                      formControlName="firstName"
                      id="firstName"
                      name="firstName"
                      [class.uk-form-danger]="
                        profileForm.get('firstName').invalid &&
                        (profileForm.get('firstName').dirty ||
                          profileForm.get('firstName').touched)
                      "
                    />
                  </div>
                </div>

                <div class="femanager_fieldset femanager_lastname uk-margin">
                  <label for="lastName" class="uk-form-label"> Nachname </label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      id="lastName"
                      type="text"
                      formControlName="lastName"
                      name="lastName"
                      [class.uk-form-danger]="
                        profileForm.get('lastName').invalid &&
                        (profileForm.get('lastName').dirty ||
                          profileForm.get('lastName').touched)
                      "
                    />
                  </div>
                </div>

                <div class="femanager_fieldset femanager_email uk-margin">
                  <label for="email" class="uk-form-label"> E-Mail </label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      id="email"
                      type="email"
                      name="email"
                      formControlName="email"
                      [class.uk-form-danger]="
                        profileForm.get('email').invalid &&
                        (profileForm.get('email').dirty ||
                          profileForm.get('email').touched)
                      "
                    />
                  </div>
                </div>

                <div class="femanager_fieldset femanager_city uk-margin">
                  <label for="femanager_field_city" class="uk-form-label">
                    Wohnort
                  </label>
                  <div class="uk-form-controls">
                    <input
                      class="uk-input"
                      id="femanager_field_city"
                      type="text"
                      formControlName="city"
                    />
                  </div>
                </div>

                <div
                  class="femanager_fieldset femanager_interests uk-margin"
                  formGroupName="interests"
                >
                  <label class="uk-form-label"> Interessen </label>

                  <div class="uk-child-width-1-2@s uk-grid-collapse" uk-grid>
                    <div
                      *ngFor="
                        let item of profileForm.get('interests').value
                          | keyvalue
                      "
                    >
                      <label>
                        <input
                          type="checkbox"
                          class="uk-checkbox"
                          [formControlName]="item.key"
                          [value]="item.value"
                        />
                        {{ item.key }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="femanager_fieldset femanager_agegroup uk-margin">
                  <label class="uk-form-label"> Altersgruppe </label>
                  <div uk-grid="" class="uk-grid">
                    <div>
                      <label *ngFor="let item of apiAgegroups">
                        <input
                          class="uk-radio"
                          formControlName="agegroup"
                          [value]="item.uid"
                          name="agegroup"
                          type="radio"
                        />
                        {{ item.title }}
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div
                class="femanager_fieldset femanager_submit uk-margin uk-grid"
                uk-grid=""
              >
                <div class="uk-width-1-4@s uk-first-column">
                  <button
                    [disabled]="submitted"
                    class="uk-button uk-button-primary uk-button-large"
                    id="femanager_field_submit"
                  >
                    <span *ngIf="submitted" uk-spinner></span>Aktualisieren
                  </button>
                </div>
                <div class="uk-width-1-4@s">
                  <!-- <a class="uk-button uk-button-default uk-button-large edit-password-link" href="/login/?tx_dvdbenutzermanager_profilemanager%5Baction%5D=editPassword&amp;tx_dvdbenutzermanager_profilemanager%5Bcontroller%5D=Profile&amp;cHash=a074e5b71f112b7bcbaf8bd70ac47a0b">
                    Passwort ändern
                  </a>-->
                </div>
                <div class="uk-width-1-2@s uk-text-right@s">
                  <a class="uk-button uk-button-danger uk-button-small delete-account-link"
                     id="delAccountLink"
                     (click)="onDeleteClick($event)"
                     >
                    <span *ngIf="deleteClicked" uk-spinner></span> Account löschen
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
