import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Presentation } from 'src/app/shared/presentation';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserData } from 'src/app/shared/user-data';
import { DomSanitizer } from '@angular/platform-browser';

declare var UIkit: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  selectedRating: number = 0;
  ratingError: boolean = false;
  ratingLoading: boolean = false;
  defaultRating: number;
  baseUrl = environment.baseUrl;
  apiPresentations: Presentation[];
  id: number;
  images: any[];
  ytvideos: string[];
  presentation: Presentation = new Presentation();
  infoboxes: any[];
  loading: boolean = true;
  favoritesLoading: boolean = false;
  userData: UserData = null;
  isFavorite: boolean = false;
  openingHours: Array<string> = [];
  infoboxesSvg: Array<string> = [
    '0 0,0 160,460 160,500 0',
    '0 0,0 100,437 100,458 0',
    '0 0,0 100,415 100,435 0',
    '0 0,0 100,394 100,413 0',
    '0 0,0 100,374 100,392 0',
    '0 0,0 100,356 100,372 0',
  ];
  infoboxesHeights: Array<number> = [170, 110, 110, 110];
  infoboxesWidths: Array<number> = [470, 427.5, 390.5];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private restApi: RestApiService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.id = parseInt(this.actRoute.snapshot.params['id']);
    this.authenticationService.currentUserData.subscribe(
      (value) => (this.userData = value)
    );
    this.actRoute.params.subscribe((routeParams) => {
      this.id = parseInt(routeParams.id);
      this.loadDestination();
    });
    this.loadDestination();
  }

  loadDestination() {
    return this.restApi.getPresentations().subscribe((res) => {
      this.apiPresentations = res.items;
      this.filterDestination();
    });
  }

  filterDestination() {
    var currentIndex = 0;
    this.infoboxes = new Array();
    this.apiPresentations.forEach((element) => {
      if (element.uid === this.id) {
        this.presentation = element;
        this.defaultRating = element.ratingAvg;
        if (this.presentation.ytvideos) {
          this.ytvideos = this.presentation.ytvideos.split(',');
        }
        this.images = Object.values(this.presentation.images);
        if (this.userData) {
          Object.values(this.userData.user.bookmarks).forEach((item) => {
            if (item.uid == this.presentation.uid) this.isFavorite = true;
          });
        }
        this.openingHours = this.presentation.openinghours
          .replace(/(\r\n|\n|\r)/gm, '')
          .split('<p>;;</p>');

        if (this.presentation.service !== '') {
          this.infoboxes.push({
            label: 'RUHR.TOPCARD Leistung',
            content: this.presentation.service,
            height: this.infoboxesHeights[currentIndex],
            width: this.infoboxesWidths[currentIndex],
            svg: this.infoboxesSvg[currentIndex],
          });
          currentIndex++;
        }
        if (this.presentation.additionalinfo !== '') {
          this.infoboxes.push({
            label: 'Zusatzinfos',
            content: this.presentation.additionalinfo,
            height: this.infoboxesHeights[currentIndex],
            width: this.infoboxesWidths[currentIndex],
            svg: this.infoboxesSvg[currentIndex],
          });
          currentIndex++;
        }
        if (this.presentation.validuntil !== '') {
          this.infoboxes.push({
            label: 'Gültig',
            content: this.presentation.validuntil,
            height: this.infoboxesHeights[currentIndex],
            width: this.infoboxesWidths[currentIndex],
            svg: this.infoboxesSvg[currentIndex],
          });
          currentIndex++;
        }
        if (this.presentation.pricewo !== '') {
          this.infoboxes.push({
            label: 'Preis ohne RUHR.TOPCARD',
            content: this.presentation.pricewo,
            height: this.infoboxesHeights[currentIndex],
            width: this.infoboxesWidths[currentIndex],
            svg: this.infoboxesSvg[currentIndex],
          });
          currentIndex++;
        }

        this.loading = false;
      }
    });
  }

  selectRating(rating: number): void {
    this.selectedRating = rating;
    this.defaultRating = rating;
  }

  addToFavorites(): void {
    var self = this;
    if (this.userData) {
      this.favoritesLoading = true;
      this.restApi
        .addFavorite(
          this.authenticationService.currentUserValue.user_id,
          this.presentation.uid
        )
        .subscribe((res) => {
          this.isFavorite = true;
          this.favoritesLoading = false;
          this.authenticationService.refreshUserData().subscribe((data) => {
            this.userData = data;
          });
        });
    } else {
      UIkit.modal
        .confirm(
          'Um Favoriten zu speichern, müsst Ihr Euch zuerst einloggen!',
          {
            labels: { ok: 'zum Login', cancel: 'Abbrechen' },
          }
        )
        .then(
          function () {
            self.router.navigate(['/account/login'], {
              queryParams: { returnUrl: self.router.url },
            });
          },
          function () {
            //console.log('Rejected.')
          }
        );
    }
  }

  deleteFromFavorites(): void {
    this.favoritesLoading = true;
    this.restApi
      .deleteFavorite(
        this.authenticationService.currentUserValue.user_id,
        this.presentation.uid
      )
      .subscribe((res) => {
        this.isFavorite = false;
        this.favoritesLoading = false;
        this.authenticationService.refreshUserData().subscribe((data) => {
          this.userData = data;
        });
      });
  }

  submitRating(): void {
    if (this.selectedRating === 0) {
      this.ratingError = true;
      return;
    }
    this.ratingError = false;
    this.ratingLoading = true;
    this.restApi
      .sendRating(
        this.presentation.uid,
        this.authenticationService.currentUserValue.user_id,
        this.selectedRating
      )
      .subscribe((res) => {
        this.ratingLoading = false;
      });
  }

  highlightElements(count: number): void {
    const ratingSpans = document.querySelectorAll('span[data-rating]');
    ratingSpans.forEach((item) => {
      item.classList.remove('highlighted');
      if (parseFloat(item.getAttribute('data-rating')) <= count)
        item.classList.add('highlighted');
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
