import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first } from 'rxjs/operators';
import { RestApiService } from 'src/app/services/rest-api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted: boolean = false;
  returnUrl: string;
  socialUser: any;
  socialError: string = null;
  socialLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private restApi: RestApiService,
    private messagingService: MessagingService,
    private socialAuthService: SocialAuthService
  ) {
    this.authenticationService.setSocialContextProcess('init');

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/account']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/account';

    this.socialAuthService.authState.subscribe(
      (user) => {
        this.socialUser = user;

        if (this.authenticationService.getSocialContextProcess() === 'init' ||
            this.authenticationService.getSocialContextProcess() === 'logout') {
          this.socialUser = null;
          this.authenticationService.setSocialContextProcess(null);
        }

        const isProviderGoogle = this.socialUser && this.socialUser.provider.toLowerCase() === 'google';
        if (isProviderGoogle) {
          this.authenticationService.setSocialContextProcess('login');
        }

        if (this.authenticationService.getSocialContextProcess() === 'login') {
          this.authenticationService.setSocialContextProcess('running');
          this.socialLogin();
        }
      },
      (error) => {
        this.socialLoading = false;
        this.socialError = error;
      }
    );

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('userid') && urlParams.get('jwt')) {
      const userid = urlParams.get('userid');
      const jwt = urlParams.get('jwt');

      this.authenticationService.socialLoginValidation(userid, jwt, 'GOOGLE')
        .subscribe((user) => {
          this.authenticationService
            .refreshUserData()
            .subscribe((data) => {
              // PushToken aktualisieren falls noch nicht belegt
              if (Notification.permission === 'granted') {
                this.messagingService.getToken().subscribe((token) => {
                  this.restApi.getToken(token).subscribe((result) => {
                    if (result.success && result.user !== data.user.uid) {
                      this.restApi
                        .updateToken(token, data.user.uid)
                        .subscribe();
                    }
                  });
                });
              }
              this.socialLoading = false;
              this.router.navigate(['/account']);
            });
        });

    }
  }

  socialLogin(): void {
    this.authenticationService
      .socialLogin(
        this.socialUser.email,
        this.socialUser.authToken,
        this.socialUser.provider
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.authenticationService
            .refreshUserData()
            .subscribe((data) => {
              // PushToken aktualisieren falls noch nicht belegt
              if (Notification.permission === 'granted') {
                this.messagingService.getToken().subscribe((token) => {
                  this.restApi.getToken(token).subscribe((result) => {
                    if (result.success && result.user !== data.user.uid) {
                      this.restApi
                        .updateToken(token, data.user.uid)
                        .subscribe();
                    }
                  });
                });
              }
              this.socialLoading = false;
              this.router.navigate([this.returnUrl]);
            });
        },
        (error) => {
          this.socialLoading = false;
          this.socialError = error;
        }
      );
  }
  loginWithFacebook(): void {
    this.socialLoading = true;
    this.authenticationService.setSocialContextPlatform('facebook');
    this.authenticationService.setSocialContextProcess('login');
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.authenticationService.refreshUserData().subscribe((data) => {
            // PushToken aktualisieren falls noch nicht belegt
            if (Notification.permission === 'granted') {
              this.messagingService.getToken().subscribe((token) => {
                this.restApi.getToken(token).subscribe((result) => {
                  if (result.success && result.user !== data.user.uid) {
                    this.restApi.updateToken(token, data.user.uid).subscribe();
                  }
                });
              });
            }

            this.router.navigate([this.returnUrl]);
          });
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
