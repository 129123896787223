<div id="navigationWrapper" class="uk-container uk-container-expand" uk-sticky>
  <div id="navigation" class="uk-position-relative">
    <div
      class="uk-navbar-container uk-navbar-transparent"
      uk-navbar="mode: click"
    >
      <div class="uk-navbar-left">
        <div class="navbar-header uk-margin-small-right">
          <a class="uk-logo" [routerLink]="'/'">
            <img src="assets/img/ruhrtopcard-blue-2019.png" alt="" />
          </a>
        </div>
      </div>

      <div class="uk-navbar-right">
        <a
          class="uk-navbar-toggle"
          href="#search-modal-full"
          uk-search-icon
          uk-toggle
        ></a>

        <a
          href="#"
          class="uk-navbar-toggle"
          uk-navbar-toggle-icon
          uk-toggle="target: #sidenav"
        >
        </a>

        <ul id="metamenu" class="uk-navbar-nav uk-visible@l">
          <li class="menu-highlight">
            <a href="/login/" [routerLink]="'/account/login'">Login</a>
          </li>
        </ul>
        <!-- <ul id="languagemenu" class="uk-navbar-nav uk-visible@l">
          <li>
            <a href="/" title="auf deutsch">
              <span class="visible-inline"> de </span>
            </a>
          </li>

          <li>
            <a href="en/" title="auf englisch">
              <span class="visible-inline"> en </span>
            </a>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</div>

<div id="sidenav" class="uk-width-1-1" uk-offcanvas="flip: true">
  <div class="uk-offcanvas-bar">
    <button class="uk-offcanvas-close" type="button" uk-close></button>

    <ul id="mainmenu_oc" class="uk-nav-parent-icon uk-margin-large-top" uk-nav>
      <li>
        <a href="#" [routerLink]="'/'" appCloseMenuOnClick>Aktuelles</a>
      </li>
      <li>
        <a href="#" [routerLink]="'/destinations/nearby'" appCloseMenuOnClick
          >Ausflugsziele</a
        >
      </li>
      <li>
        <a href="#" [routerLink]="'/info'" appCloseMenuOnClick
          >Wichtige Hinweise zur Nutzung</a
        >
      </li>
      <li>
        <a href="#" [routerLink]="'/faq'" appCloseMenuOnClick
          >Häufig gestellte Fragen</a
        >
      </li>
      <li>
        <a href="#" [routerLink]="'/technischer-support'" appCloseMenuOnClick
          >Technischer Support</a
        >
      </li>

      <!--
      <li class="uk-parent">
        <a href="#" class="uk-width-1-1">
          Service
          <span
            class="uk-align-right uk-margin-remove"
            uk-icon="icon: chevron-left"
          ></span>
          <!--<span class="caret"></span>
        </a>

        <ul class="uk-nav-sub">
          <li>
            <a href="/service/aktuelles/">Aktuelles</a>
          </li>

          <li>
            <a href="/service/haeufig-gestellte-fragen/"
              >Häufig gestellte Fragen</a
            >
          </li>

          <li>
            <a href="/service/infomaterial/">Infomaterial</a>
          </li>

          <li>
            <a target="_blank" href="/service/presse-medien/"
              >Presse &amp; Medien</a
            >
          </li>

          <li>
            <a href="/service/kontakt/">Kontakt</a>
          </li>
        </ul>
      </li>
    -->

      <li class="p-309 uk-margin-top">
        <a href="#" [routerLink]="'/account/login'" appCloseMenuOnClick
          >Login</a
        >
      </li>

      <li *ngIf="currentUser" class="uk-margin-small-left">
        <a [routerLink]="['/account/profile']" appCloseMenuOnClick> Profil </a>
      </li>

      <li *ngIf="currentUser" class="uk-margin-small-left">
        <a [routerLink]="['/account/favorites']" appCloseMenuOnClick>
          Favoriten
        </a>
      </li>

      <li *ngIf="currentUser" class="uk-margin-small-left">
        <a [routerLink]="['/account/mycard']" appCloseMenuOnClick> MyCard </a>
      </li>

      <!-- <li class="p-311">
        <a
          href="https://www.ruhrtopcard.de/online-bestellen/"
          class=""
          target="_blank"
          >Online bestellen</a
        >
      </li> -->
    </ul>

    <!-- derzeit nur deutsch
    <div class="uk-margin-top">
      <a href="/" title="auf deutsch">
        <span class="visible-inline">de</span>
      </a>
      &nbsp; &nbsp;
      <a href="/en/" title="in english">
        <span class="visible-inline">en</span>
      </a>
    </div>
    -->
    <div class="uk-margin-top">
      <ul id="mainmenu_footer" class="uk-nav-parent-icon" uk-nav>
        <li class="uk-margin-bottom">
          <a href="#" [routerLink]="'/settings'" appCloseMenuOnClick
            ><span uk-icon="settings"></span> Einstellungen</a
          >
        </li>
        <li>
          <a href="#" [routerLink]="'/agb'" appCloseMenuOnClick>AGB</a>
        </li>
        <li>
          <a href="#" [routerLink]="'/privacy'" appCloseMenuOnClick
            >Datenschutzerklärung</a
          >
        </li>
        <li>
          <a href="#" [routerLink]="'/impressum'" appCloseMenuOnClick
            >Impressum</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<div id="search-modal-full" class="uk-modal-full uk-modal" uk-modal>
  <div class="uk-modal-dialog uk-flex uk-flex-center" uk-height-viewport>
    <button class="uk-modal-close-full" type="button" uk-close></button>
    <form class="uk-search uk-margin-large-top">
      <input
        class="uk-search-input uk-text-center"
        [(ngModel)]="search"
        name="search"
        id="searchfield"
        type="search"
        placeholder="Ausflugsziel suchen..."
        autocomplete="off"
        autofocus
      />
      <div *ngIf="search.length > 2">
        <ul
          id="searchlist"
          *ngIf="(apiPresentations | searchFilter: search).length > 0"
        >
          <li *ngFor="let entry of apiPresentations | searchFilter: search">
            <a
              [routerLink]="'/destinations/detail/' + entry.uid"
              (click)="closeSearch()"
              >{{ entry.title }}</a
            >
          </li>
        </ul>
      </div>
    </form>
  </div>
</div>
