import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestApiService } from '../../services/rest-api.service';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { News } from 'src/app/shared/news';

import * as sha1 from 'js-sha1';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  newsItems: News[];
  loading: boolean = true;

  constructor(private restApi: RestApiService, private router: Router) {}

  ngOnInit(): void {
    this.restApi.getNews().subscribe((data) => {
      this.newsItems = data.items;
      this.newsItems.sort((a, b) => {
        return new Date(a.datetime).getTime() > new Date(b.datetime).getTime()
          ? -1
          : new Date(a.datetime).getTime() < new Date(b.datetime).getTime()
          ? 1
          : 0;
      });
      this.loading = false;
    });
  }

  hashCode(idString): string {
    return sha1(idString);
  }
}
