import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { AccountComponent } from './pages/account/account.component';
import { FavoritesComponent } from './pages/account/favorites/favorites.component';
import { LoginComponent } from './pages/account/login/login.component';
import { MycardComponent } from './pages/account/mycard/mycard.component';
import { ProfileComponent } from './pages/account/profile/profile.component';
import { AgbComponent } from './pages/agb/agb.component';
import { CitiesComponent } from './pages/destinations/cities/cities.component';
import { DestinationsComponent } from './pages/destinations/destinations.component';
import { DetailComponent } from './pages/destinations/detail/detail.component';
import { NearbyComponent } from './pages/destinations/nearby/nearby.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { InfoComponent } from './pages/info/info.component';
import { NewsComponent } from './pages/news/news.component';
import { PasswordComponent } from './pages/account/password/password.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RegisterComponent } from './pages/account/register/register.component';
import { TechnischerSupportComponent } from './pages/technischer-support/technischer-support.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'news/:id',
    component: NewsComponent,
  },
  {
    path: 'destinations',
    component: DestinationsComponent,
  },
  {
    path: 'destinations/nearby',
    component: NearbyComponent,
  },
  {
    path: 'destinations/cities',
    component: CitiesComponent,
  },
  {
    path: 'destinations/detail/:id',
    component: DetailComponent,
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/mycard',
    component: MycardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/favorites',
    component: FavoritesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account/login',
    component: LoginComponent,
  },
  {
    path: 'account/password',
    component: PasswordComponent,
  },
  {
    path: 'account/register',
    component: RegisterComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  {
    path: 'agb',
    component: AgbComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: 'technischer-support',
    component: TechnischerSupportComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
