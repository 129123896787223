import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[appLogoutButton]'
})
export class LogoutButtonDirective {

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  @HostListener('click')
  onClick() {
    this.authenticationService.setSocialContextProcess('logout');
    this.authenticationService.logout();
    this.router.navigate(['/account/login']);
  }

}
