// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'https://test.ruhrtopcard.de/rest/v1',
  //baseUrl: 'https://test.ruhrtopcard.de/',
  apiUrl: 'https://www.ruhrtopcard.de/rest/v1',
  baseUrl: 'https://www.ruhrtopcard.de/',
  registerUrl: 'https://test.ruhrtopcard.de/login/registrierung/',
  passwordUrl: 'https://test.ruhrtopcard.de/login/?tx_felogin_pi1[forgot]=1',
  faqUrl: 'https://www.ruhrtopcard.de/service/haeufig-gestellte-fragen/',
  infoUrl:
    'https://www.ruhrtopcard.de/ruhrtopcard/wichtige-hinweise-zur-nutzung/',
  agbUrl: 'https://www.ruhrtopcard.de/bestellung/agb/',
  privacyUrl: 'https://www.ruhrtopcard.de/datenschutzerklaerung/',
  impressumUrl: 'https://test.ruhrtopcard.de/impressum/',
  technischerSupportUrl: 'https://www.ruhrtopcard.de/technischer-support/',
  firebase: {
    projectId: 'pwa-ruhrtopcard-950af',
    appId: '1:845145230531:web:3a157da2e1321a537c2676',
    databaseURL: 'https://pwa-ruhrtopcard-950af.firebaseio.com',
    storageBucket: 'pwa-ruhrtopcard-950af.appspot.com',
    apiKey: 'AIzaSyDQXdiDwwoO6Epaudfh-7iadLxeqwAtZrg',
    authDomain: 'pwa-ruhrtopcard-950af.firebaseapp.com',
    messagingSenderId: '845145230531',
    measurementId: 'G-NBRLL93L33',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
