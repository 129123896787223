import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/services/rest-api.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Presentation } from 'src/app/shared/presentation';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements OnInit {
  apiPresentations: Presentation[];
  apiCities: Array<string>;
  cities: Array<City> = [];
  loading: boolean = true;

  selectedAlphabet: string;

  constructor(
    private restApi: RestApiService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.selectedAlphabet = this.settingsService.citiesAtoZ();
    this.restApi.getPresentations().subscribe((data) => {
      this.apiPresentations = data.items;
      this.buildCityList();
      this.applyFilters();
      this.loading = false;
    });
  }

  buildCityList(): void {
    this.apiCities = [];
    this.apiPresentations.forEach((item) => {
      if (this.apiCities.indexOf(item.cityalpha) == -1)
        this.apiCities.push(item.cityalpha);
    });
  }

  applyFilters(): void {
    this.settingsService.citiesAtoZ(this.selectedAlphabet);
    this.cities = [];

    this.apiCities.forEach((item) => {
      var match: boolean = true;

      // Buchstaben
      if (this.selectedAlphabet !== 'all') {
        var alphabetArray: Array<string> = this.selectedAlphabet.split('-');
        if (
          item.charAt(0).toLowerCase() < alphabetArray[0] ||
          item.charAt(0).toLowerCase() > alphabetArray[1]
        )
          match = false;
      }

      if (match) {
        var presentations: Array<any> = [];
        this.apiPresentations.forEach((pItem) => {
          if (pItem.cityalpha === item) {
            presentations.push(pItem);
          }
        });

        var city = new City();
        city.cityalpha = item;
        city.presentations = presentations;

        this.cities.push(city);
      }

      this.cities.sort((a, b) => {
        if (a.cityalpha == '' && b.cityalpha != '') return 1;
        if (a.cityalpha != '' && b.cityalpha == '') return -1;

        return a.cityalpha > b.cityalpha
          ? 1
          : b.cityalpha > a.cityalpha
          ? -1
          : 0;
      });
    });
  }
}

export class City {
  cityalpha: string;
  presentations: Array<Presentation>;
}
