export class News {
  author?: string;
  title: string;
  bodytext: string;
  datetime: Date;
  description: string;
  tags: string[];
  teaser: string;
  media: any[];
}
