import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { UserData } from 'src/app/shared/user-data';
import { environment } from 'src/environments/environment';

declare var UIkit: any;
@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit {
  baseUrl = environment.baseUrl;
  currentAccountPage: string = '/account/favorites';
  userData: UserData;
  favorites: Array<any>;

  constructor(
    private authenticationService: AuthenticationService,
    private restApi: RestApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadUser();
    this.refresh();
  }

  loadUser() {
    this.authenticationService.currentUserData.subscribe(
      (value) => (this.userData = value)
    );
  }

  refresh() {
    this.favorites = Object.values(this.userData.user.bookmarks);
    this.favorites.forEach((item) => {
      item.dispImages = Object.values(item.images);
    });
  }

  deleteFromFavorites(uid: number): void {
    var self = this;
    UIkit.modal
      .confirm('Wirklich von Favoriten entfernen?', {
        labels: { ok: 'Enfternen', cancel: 'Abbrechen' },
      })
      .then(
        function () {
          self.restApi
            .deleteFavorite(
              self.authenticationService.currentUserValue.user_id,
              uid
            )
            .subscribe((res) => {
              self.authenticationService.refreshUserData().subscribe((data) => {
                self.userData = data;
                self.refresh();
              });
            });
        },
        function () {
          //console.log('Rejected.')
        }
      );
  }

  changeAccountPage() {
    const selectedAccountPage: string = this.currentAccountPage;
    this.currentAccountPage = '/account/favorites';
    this.router.navigate([selectedAccountPage]);
  }
}
